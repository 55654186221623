/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as url from "helper/UrlHelper";

const ContactModal = ({ myContacts, handleConnectSelect }) => {
  const [searchValue, setSearchValue] = useState("");

  //function for set filter leads
  const filterSearchContacts = () => {
    return myContacts.filter((contact) => {
      const searchTerm = searchValue.toLowerCase().trim();
      const nameMatch = contact.contactname.toLowerCase().includes(searchTerm);

      return nameMatch;
    });
  };

  return (
    <div className="chat_modalbx">
      <div className="modal fade" id="start_newchat">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">Start New Chat</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-round">close </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="group_srchfill add_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name or email"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                {/* <Link to="#" className="add_icon">
                  <span className="material-icons-round">add_circle </span>
                </Link> */}
              </div>
              <div className="group_innrScroll">
                {myContacts.map((contact, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        filterSearchContacts().includes(contact)
                          ? "chat_usrRow cursor-pointer"
                          : "d-none"
                      }
                    >
                      <Link
                        to="#"
                        data-bs-dismiss="modal"
                        // aria-label="Close"
                        onClick={() => {
                          handleConnectSelect(
                            contact.userid,
                            contact.contactname,
                            contact.contactimage,
                            contact.contactemail,
                            "contact",
                            contact._id,
                            contact.contactphone,
                            contact.contactcity + ", " + contact.contactcountry,
                            contact.contactcompany
                          );
                        }}
                      >
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            <img
                              src={url.SERVER_URL + contact.contactimage}
                              alt=""
                            />
                          </div>
                          <div className="user_name">
                            <h3>{contact.contactname}</h3>
                          </div>
                        </div>
                        {/* <div className="members_check blue_bullet">
                      <span></span>
                    </div> */}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
