/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { getData, putData, uploadSingleFile } from "utils/Gateway";

import JobHeaderSection from "../LeftPanel/Sections/JobHeaderSection";

import { assetImages } from "constants";

import { getUpdatedConvertedDate } from "helper/Common/CommonHelper";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

const CvBuilderRightPanel = ({
  setcvId,
  reloadCvList,
  setreloadCvList,
  setStartFetchFromProfile,
  cvContent,
}) => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [cvList, setcvList] = useState([]); //cv list requirements

  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [minSalary, setminSalary] = useState("");
  const [maxSalary, setmaxSalary] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [companyLogoPath, setCompanyLogoPath] = useState("");
  const [jobTypeValue, setjobTypeValue] = useState("");
  const [hideApplyButton, setHideApplyButton] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [applyCount, setApplyCount] = useState(0);
  const [jobResponses, setJobResponses] = useState([]); // all responses by loggedin user for this job

  //** function for get job details */
  const getJobDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log('job details response', response);

      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
        setSummary(data.summary);
        setCompanyLogoPath(data.companylogopath);
        setminSalary(data.minsalary);
        setmaxSalary(data.maxsalary);
        setjobTypeValue(data.jobtype);
        setcompanyName(data.companynamestring);
        setIsApplied(data.isappiled);
        setApplyCount(data.applicationcount);
        setJobResponses(data.challengeresponses);

        if (
          userInfo._id.toString() === data.moderator._id.toString() ||
          data?.challengemembers?.some(
            (item) => item._id.toString() === userInfo._id.toString()
          )
        ) {
          setHideApplyButton(true);
        } else {
          setHideApplyButton(false);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** function for get all categories
  // const getAllCv = async () => {
  //   try {
  //     let requestUrl =
  //       url.API_BASE_URL + url.API_GET_ALL_CV + `?token=${token}`;

  //     if(params.id){
  //       requestUrl += `&challengeid=${params.id}`;
  //     }

  //     const response = await getData(requestUrl);

  //     console.log(response);

  //     if (response.status) {
  //       setcvList(response.data);
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  //** function for change status
  const changeStatusHandler = async (id) => {
    try {
      let cvData = {
        status: "0",
      };
      let requestUrl =
        url.API_BASE_URL + url.API_UPDATE_CV + `/${id}` + `?token=${token}`;

      const response = await putData(requestUrl, cvData);

      if (response.status) {
      }
    } catch (error) {
      console.log(error.message);
    }

    getAllCv();
  };

  //** use effect for reload cv list function after creating a new cv */
  useEffect(() => {
    if (params.id) {
      getJobDetails();
    }
  }, [params.id]);


  return (

    <>
    <div className="col-lg-6">
      <div className="builder_lrightpnl">
        {/* <!-- ========== CV builder action buttons ========== --> */}
        <div
          className={`download_btn d-flex gap-2 mb-3 ${
            params && params.id ? "" : "d-none"
          }`}
        >
          {/* <!-- ========== Start fetch from profile button ========== --> */}
          <button
            className="btn "
            onClick={() => {
              //setStartFetchFromProfile(true);
            }}
          >
            <i class="material-icons me-2">psychology</i>
            Ask AI help for job below
          </button>
          {/* <!-- ========== End fetch from profile button ========== --> */}

          {/* <!-- ========== Ask to expert ========== --> */}
          <button
            className="btn "
            // onClick={() => {
            //   setStartFetchFromProfile(true);
            // }}
          >
            <i class="material-icons me-2">person_pin</i>
            Ask to Expert
          </button>
          {/* <!-- ========== Ask to expert ========== --> */}

          {/* <!-- ========== Start fetch from uploaded doc button ========== --> */}
          {/* <Link
            className="btn btn-primary fetch-btn flex-fill"
            to={`/jobresponse/clientfeedback/${lastSumbittedResponse}`}
            target="_blank"
          >
            Send last response to customer
          </Link> */}
          {/* <!-- ========== End fetch from uploaded doc button ========== --> */}
        </div>
        {/* <!-- ========== CV builder action buttons ends ========== --> */}

        {/* <!-- ========== Start job header component ========== --> */}
        <JobHeaderSection
          companyName={companyName}
          companyLogoPath={companyLogoPath}
          title={title}
          summary={summary}
          jobTypeValue={jobTypeValue}
          maxSalary={maxSalary}
          minSalary={minSalary}
          isApplied={isApplied}
          applyCount={applyCount}
        />
        {/* <!-- ========== End job header component ========== --> */}
        


        {/* <!-- ========== Start previous created cv list Section ========== --> */}
        <div className="white_shadow_bx bg-light previous_cvbx py-4 px-3">

          <h3 className="mb-3 d-flex justify-content-between">
            Submitted Responses 
            <Link to="#" className="ms-auto" onClick={getJobDetails}>
              <i className="material-icons-outlined">refresh</i>
            </Link>
          </h3>

          <div className="cv_pnl">
            {jobResponses.map((jobResponse, index) => {

              return (
                <div
                  className="cv_row p-3 d-flex align-items-center justify-content-between"
                  key={index}
                >
                  <div className="cv_left_text">
                    <h4 className="mb-1">{jobResponse.responsecode} {" : "} 
                      {jobResponse.candidatecvid?.resumetitle??""}</h4>
                    <h5>{getUpdatedConvertedDate(jobResponse.updatedAt)}</h5>
                    <h5>Approval Status : </h5>
                  </div>
                  <div className="cv_more_btn dropdown user_right">
                    <Link
                      to="#"
                      className="d-inline-block"
                      data-bs-toggle="dropdown"
                    >
                      <i className="material-icons-outlined">more_vert </i>
                    </Link>
                    <ul className="dropdown-menu list_stye_none" >
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center gap-1"
                          onClick={() => {
                            setcvId(jobResponse.candidatecvid?._id??null);
                          }}
                        >
                          <i className="material-icons-outlined">visibility</i>
                          <span>View CV</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center gap-1"
                        >
                          <i className="material-icons-outlined">assignment</i>
                          <span>Send to Customer</span>
                        </Link>
                      </li>
                   
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End previous created cv list Section ========== --> */}
      </div>
    </div>

    {/* Modal view of customer template ----------------------- */}

      <div className="modal fade show" id="customertemplate_modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>View as Customer Template</h3>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined">close </i>
              </button>
            </div>
            <div className="modal-body">

                      {/* <!-- ========== Start AI and skills Section ========== --> */}
                      <div className="lobees_aibx px-3 py-4 white_shadow_bx mb-4">
                        {/* <div className="logo_summery_top d-flex gap-3 align-items-start">
                          <span className="ai_logo d-block rounded-circle overflow-hidden">
                            <img src={assetImages.aiLogo} alt="" />
                          </span>
                          <div className="ai_info_top">
                            <h3>Ai Recommendation</h3>
                            <h6>Current CV is 80% completed</h6>
                          </div>
                        </div> */}

                        {/* Basic info ------------------------ */}
                        <div className="summery_bx p-3 mb-4 mt-1">
                          
                          <div className="builder_usr_row d-flex justify-content-between">
                            <div className="build_usrleft_pnl d-flex gap-3">
                                <div className="build_usrpic position-relative">
                                    <figure>
                                      <img src= {
                                                  cvContent.userImagePath == ""
                                                    ? assetImages.defaultUser
                                                    : url.SERVER_URL + cvContent.userImagePath
                                                } alt="" 
                                      />
                                    </figure>                      
                                </div>
                                <div className="build_usrinfo">
                                    <h3>{cvContent.userName} {cvContent.userSurname}
                                      <span className="d-block">{cvContent.userPosition}</span>
                                    </h3>
                                    <ul className="list_stye_none d-flex align-items-center mt-3 gap-4 mb-3 flex-wrap">
                                        <li className="d-flex align-items-center gap-2">
                                            <i className="material-icons">email</i>
                                            <span>{cvContent.userEmail}</span>
                                        </li>
                                        <li className="d-flex align-items-center gap-2">
                                            <i className="material-icons">phone</i>
                                            <span>{cvContent.userPhone}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                          </div>
                          
                        </div>
                        
                        {/* Summary ---------------------------- */}
                        <div className="skill_bx p-3 mb-2">
                          <h3 className="mb-3">Summary</h3>
                          <p>{cvContent.userSummary}</p>
                        </div>

                        {/* <!-- ========== Career highlights ========== --> */}
                        <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                          <h3 className="mb-3">Career Highlights</h3>
                          <div className="cv_pnl">
                            {cvContent.userCarrierHighlights && cvContent.userCarrierHighlights.map((carrierhighlight, index) => {
                              return (
                                <div
                                  className="cv_row p-3 d-flex align-items-center justify-content-between"
                                  key={index}
                                >
                                  <div className="cv_left_text">
                                    <h5>{carrierhighlight.highlighttext}</h5>

                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* <!-- ========== Career highlights ========== --> */}

                        {/* Experience ---------------------------- */}
                        <div className="skill_bx p-3 mb-2">
                          <h3 className="mb-3">Experience </h3>
                          <p>{cvContent.userExperienceInYears} + Years</p>
                        </div>

                        {/* Roles ---------------------------- */}
                        <div className="skill_bx p-3 mb-2">
                          <h3 className="mb-3">Roles</h3>
                          <ul className="list_stye_none d-flex flex-wrap gap-2">
                            {cvContent.userRoles && cvContent.userRoles.map((roleData, index) => {
                                return (
                                <li key={index}>
                                  <Link
                                    to="#"
                                    className="d-flex align-items-center gap-1 btn white_btn"
                                  >
                                    <span>{roleData.rolestext}</span>
                                  </Link>
                                </li>
                                )
                            })}
                          </ul>
                        </div>
                        
                        {/* Skills ----------------------------------- */}
                        <div className="skill_bx p-3">
                          <h3 className="mb-3">Skills</h3>
                          <ul className="list_stye_none d-flex flex-wrap gap-2">
                            {cvContent.skillPointListBlock && cvContent.skillPointListBlock.map((skillBlock, index) => {
                                return (
                                <li key={index}>
                                  <Link
                                    to="#"
                                    className="d-flex align-items-center gap-1 btn white_btn"
                                  >
                                    <i className="material-icons-outlined">add</i>
                                    <span>{skillBlock?.skill
                                          ? skillBlock?.skill?.label
                                          : skillBlock?.skillname}{" "}</span>
                                  </Link>
                                </li>
                                )
                            })}
                          </ul>
                        </div>
                      
                      </div>
                      {/* <!-- ========== End AI and skills Section ========== --> */}

                      {/* <!-- ========== Education section ========== --> */}
                      <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                        <h3 className="mb-3">Education</h3>
                        <div className="cv_pnl">
                          {cvContent.educationListBlock && cvContent.educationListBlock.map((education, index) => {
                            return (
                              <div
                                className="cv_row p-3 d-flex align-items-center justify-content-between"
                                key={index}
                              >
                                <div className="cv_left_text">
                                  <h4 className="mb-1">{education.degree}</h4>
                                  <h5>from {education.institute} in year {education.year} </h5>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <!-- ========== Education section ========== --> */}

                      {/* <!-- ========== Experience section ========== --> */}
                      <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                        <h3 className="mb-3">Experience</h3>
                        <div className="cv_pnl">
                          {cvContent.experienceListBlock && cvContent.experienceListBlock.map((experience, index) => {
                            return (
                              <div
                                className="cv_row p-3 d-flex align-items-center justify-content-between"
                                key={index}
                              >
                                <div className="cv_left_text">
                                  <h4 className="mb-1">{experience.position}</h4>
                                  <h5>at{" "}
                                          {experience.companyid
                                            ? experience.companyid.label
                                            : experience.company} 

                                          <i className="material-icons ms-2">date_range</i> {" "}

                                          {experience.start.length > 5
                                          ? getDateFormatInSpanish(experience.start) +
                                            " - " +
                                            getDateFormatInSpanish(experience.end)
                                          : experience.start + " - " + experience.end}</h5>

                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <!-- ========== Experience section ========== --> */}

                      {/* <!-- ========== Languages section ========== --> */}
                      <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                        <h3 className="mb-3">Languages</h3>
                        <div className="skill_bx p-3">
                          <ul className="list_stye_none d-flex flex-wrap gap-2">
                          
                            {cvContent.userLanguages && cvContent.userLanguages.map((language, index) => {
                                return (
                                <li key={index}>
                                  <Link
                                    to="#"
                                    className="d-flex align-items-center gap-1 btn white_btn"
                                  >
                                    <span>{language.languagetext}{" "}</span>
                                  </Link>
                                </li>
                                )
                            })}
                          </ul>
                        </div>
                      </div>
                      {/* <!-- ========== Languages section ========== --> */}

                      {/* <!-- ========== Soft skills section ========== --> */}
                      <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                        <h3 className="mb-3">Soft Skills</h3>
                        <div className="skill_bx p-3">
                          <ul className="list_stye_none d-flex flex-wrap gap-2">
                          
                            {cvContent.userOtherSkills && cvContent.userOtherSkills.map((skillData, index) => {
                                return (
                                <li key={index}>
                                  <Link
                                    to="#"
                                    className="d-flex align-items-center gap-1 btn white_btn"
                                  >
                                    <span>{skillData.skilltext}{" "}</span>
                                  </Link>
                                </li>
                                )
                            })}
                          </ul>
                        </div>
                      </div>
                      {/* <!-- ========== Soft skill section ========== --> */}

                      {/* <!-- ========== Hobbies section ========== --> */}
                      <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                        <h3 className="mb-3">Hobbies</h3>
                        <div className="skill_bx p-3">
                          <ul className="list_stye_none d-flex flex-wrap gap-2">
                          
                            {cvContent.userHobbies && cvContent.userHobbies.map((hobbiesData, index) => {
                                return (
                                <li key={index}>
                                  <Link
                                    to="#"
                                    className="d-flex align-items-center gap-1 btn white_btn"
                                  >
                                    <span>{hobbiesData.hobbiestext}{" "}</span>
                                  </Link>
                                </li>
                                )
                            })}
                          </ul>
                        </div>
                      </div>
                      {/* <!-- ========== Hobbies section ========== --> */}

                      {/* <!-- ========== Certification section ========== --> */}
                      <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                        <h3 className="mb-3">Technical Certificates</h3>
                        <div className="cv_pnl">
                          {cvContent.technicalExpBlock && cvContent.technicalExpBlock.map((techExpData, index) => {
                            return (
                              <div
                                className="cv_row p-3 d-flex align-items-center justify-content-between"
                                key={index}
                              >
                                <div className="cv_left_text">
                                  <h4 className="mb-1">{techExpData.name}{" "}
                                        <p>
                                          From {techExpData.companyname}
                                          <span> with grade: {techExpData.grade}</span>
                                        </p>
                                  </h4>
                                  <h5>
                                      {getDateFormatInSpanish(techExpData.start)} -{" "}
                                      {getDateFormatInSpanish(techExpData.end)}
                                  </h5>

                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <!-- ========== certification section ========== --> */}
                      

                      {/* <!-- ========== Projects section ========== --> */}
                      <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                        <h3 className="mb-3">Project References</h3>
                        <div className="cv_pnl">
                          {cvContent.projectListBlock && cvContent.projectListBlock.map((project, index) => {
                            return (
                              <div
                                className="cv_row p-3 d-flex align-items-center justify-content-between"
                                key={index}
                              >
                                <div className="cv_left_text">
                                  <h4 className="mb-1">
                                      {project.company}
                                      <span>
                                        {" "}
                                        || {" "}
                                        {project.start.length > 5
                                        ? getDateFormatInSpanish(project.start) +
                                          " - " +
                                          getDateFormatInSpanish(project.end)
                                        : project.start + " - " + project.end}
                                      </span>

                                      <p>
                                        Role : {project.position}
                                      </p>
                                      <p>
                                        Technologies : {project.technologies}
                                      </p>
                                  </h4>
                                  <h5>
                                      {project.responsibilities} 
                                  </h5>

                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <!-- ========== Projects section ========== --> */}

                      {/* <!-- ========== Skill with points section ========== --> */}
                      <div className="white_shadow_bx previous_cvbx py-4 px-3 mb-4">
                        <h3 className="mb-3">Skill Points</h3>
                        <div className="cv_pnl">
                          {cvContent.skillPointListBlock && cvContent.skillPointListBlock.map((skillBlock, index) => {
                            return (
                              <div
                                className="cv_row p-3 d-flex align-items-center justify-content-between"
                                key={index}
                              >
                                <div className="cv_left_text">
                                  <h4 className="mb-1">
                                    {skillBlock?.skill
                                          ? skillBlock?.skill?.label
                                          : skillBlock?.skillname}{" "}
                                        <span>with rating {skillBlock.points}/10 </span>
                                  </h4>
                                  <h5>
                                    Experience
                                    <span> {skillBlock.experienceyear} year</span>
                                  </h5>

                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <!-- ========== Skill with points section ========== --> */}

            </div>
          </div>
        </div>
      </div>
  
    {/* Modal view ends here ---------------------------------- */}
    </>


  );
};

export default CvBuilderRightPanel;
