/* eslint-disable */
import React, { useEffect } from "react";

import ConversationRightAiPanel from "./ConversationRightAiPanel/ConversationRightAiPanel";

import ConversationRightContactPanel from "./ConversationRightContactPanel/ConversationRightContactPanel";

import ConversationRightWelcomePanel from "./ConversationRightWelcomePanel/ConversationRightWelcomePanel";

const ConversationRightPanel = ({
  defaultScreen,
  selectedConversationId,
  conversationName,
  selectedChatLogo,
  selectedChatEmail,
  selectedChatRoomId,
  selectedChatPhone,
  selectedChatAddress,
  selectedChatCompany,
  reloadChatPanel = false,
  setreloadChatPanel,
  chatcomponentName,
  setchatcomponentName,
  chatComponentTitle,
  setchatComponentTitle,
}) => {
  // useEffect(() => {
  //   console.log("defaultScreen 2", defaultScreen);
  // }, [defaultScreen]);

  return (
    <div className="chat_rightpnl">
      {defaultScreen === "ai" ? (
        <ConversationRightAiPanel />
      ) : defaultScreen === "contact" ? (
        <ConversationRightContactPanel
          selectedContactUserId={selectedConversationId}
          conversationName={conversationName}
          selectedChatLogo={selectedChatLogo}
          selectedChatEmail={selectedChatEmail}
          selectedChatRoomId={selectedChatRoomId}
          selectedChatPhone={selectedChatPhone}
          selectedChatAddress={selectedChatAddress}
          selectedChatCompany={selectedChatCompany}
          reloadChatPanel={reloadChatPanel}
          setreloadChatPanel={setreloadChatPanel}
          chatcomponentName={chatcomponentName}
          setchatcomponentName={setchatcomponentName}
          chatComponentTitle={chatComponentTitle}
          setchatComponentTitle={setchatComponentTitle}
        />
      ) : defaultScreen === "welcome" ? (
        <ConversationRightWelcomePanel />
      ) : (
        ""
      )}
    </div>
  );
};

export default ConversationRightPanel;
