export const getDateFormatInSpanish = (dateString) => {
  if (dateString && dateString !== "") {
    const parts = dateString.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate; // Output: 17/10/2018
  } else {
    return "";
  }
};

export const getUpdatedConvertedDate = (dateString) => {
  if (dateString && dateString !== "") {
    const createdAt = new Date(dateString);

    // Formatting date
    const formattedDate = createdAt.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    // Formatting time
    const formattedTime = createdAt.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Adjusting time format to AM/PM
    const amPmTime = formattedTime.replace(
      /^(\d{1,2}:\d{2}) (AM|PM)$/,
      "$1 $2"
    );

    // Combining date and time
    const formattedDateTime = `${formattedDate} at ${amPmTime}`;

    return formattedDateTime; // Output: 05/03/2024 at 01:04 PM
  } else {
    return "";
  }
};

export const formatNumber = (number = 0) => {
  return new Intl.NumberFormat("de-DE").format(number);
};
