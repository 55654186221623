import React from 'react';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const DeveloperHowWeDo = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section className="we_doSec pd_130">
      <div className="container">
        <div className="page_hdng dark_grnheading text-center">
          <h5>{t('How do we do it')}?</h5>
          <h2>
            {t('Simple Steps we')} <br /> {t('follow on Our')} <br />{' '}
            {t('Timeline')}
          </h2>
        </div>
        <div className="timeline_pic">
          <img src="images/timeline.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default DeveloperHowWeDo;
