/* eslint-disable */
import { useState, createContext, useEffect } from "react";

export const GlobalProvider = createContext();

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const GlobalContext = (props) => {
  const token = localStorage.getItem("token");

  /*======= login requirement start =======*/
  const [createAccountMessage, setcreateAccountMessage] = useState("");
  const [userEmailforOtp, setuserEmailforOtp] = useState("");
  /*======= login requirement end =======*/

  //filter requirements
  const [ecosystemList, setEcosystemList] = useState([]);

  //function for get all modules
  const getAllEcosystems = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_ECOSYSTEM + `?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setEcosystemList(
          response.data.map((item) => ({
            label: item.name,
            value: item._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  let screenHeight = window.innerHeight;

  useEffect(() => {
    getAllEcosystems();
  }, []);

  return (
    <GlobalProvider.Provider
      value={{
        /*======= screen height =================*/
        screenHeight,

        //ecosystem options
        ecosystemList,
        /*======= login requirement start =======*/
        createAccountMessage,
        setcreateAccountMessage,
        userEmailforOtp,
        setuserEmailforOtp,
        /*======= login requirement end =======*/
      }}
    >
      {props.children}
    </GlobalProvider.Provider>
  );
};

export default GlobalContext;
