//Web server base url
export const API_BASE_URL = "https://dev.api.lobees.com/api";

export const SERVER_URL = "https://dev.api.lobees.com/";

export const BACKEND_BASE_URL = "https://dev.admin.lobees.com"; // Backend url

export const FRONTEND_BASE_URL = "https://dev.lobees.com"; // For frontend

//Local Urls
// export const API_BASE_URL = "http://localhost:4006/api"; // NODE SERVER URL FOR API

// export const SERVER_URL = "http://localhost:4006";

// export const BACKEND_BASE_URL = "http://localhost:4600"; // Backend OR ADMIN PANEL url

// export const FRONTEND_BASE_URL = "http://localhost:4204"; // frontend

/*=========== all Demo Data API URL start here ===========*/
export const DEMO_GET = "/demo/demoget"; // for get demo data

export const DEMO_POST = "/demo/demopost"; // for testing post data
/*=========== all Demo Data API URL end here ===========*/

/*======= login and registration url start =======*/
export const API_LOGIN = "/auth/login"; //login url

export const API_REGISTRATION = "/auth/register"; //registration url

export const API_GET_OTP = "/auth/getotp"; //for getting otp

export const API_VALIDATE_OTP = "/auth/validateotp"; //for validating otp

export const API_UPDATE_PASSSWORD = "/auth/updatepassword"; //for update passowrd

export const API_AUTH_VALIDATE = "/auth/validateuser"; //validation url

/*======= login and registration url end =======*/

/*======= File upload url start =======*/
export const API_SINGLE_FILE_UPLOAD = "/upload/singleupload"; //for single file upload

export const API_MULTIPLE_FILE_UPLOAD = "/upload/multiupload"; //for multiple file upload
/*======= File upload url end =======*/

/*======= URL to upload Image in Text Editor start =======*/
export const URL_TEXT_EDITOR_IMAGE_UPLOAD = "";

export const URL_TEXT_EDITOR_IMAGE_UPLOAD_SECOND = "";
/*======= URL to upload Image in Text Editor end =======*/

//get all ecosystem
export const API_GET_ALL_ECOSYSTEM = "/ecosystem/getecosystems";

//get all module access
export const API_GET_ALL_MODULE_ACCESS = "/moduleroleprevilege/getmoduleaccess";

//API Categories
export const API_GET_CATEGORIES = "/category/getcategories";

/*----- tag/label url start ------*/
//create tag
export const API_CREATE_NEW_TAG = "/tag/createtag";

//get all tag
export const API_GET_ALL_TAGS = "/tag/getalltags";
/*----- tag/label url end ------*/

/*====== lead url start ======*/
//create lead
export const API_SAVE_GUEST_LEAD = "/lead/saveguestlead";

/*====== lead url end ======*/

/*====== blog url start ======*/
//get all blog with category
export const API_GET_ALL_BLOG_WITH_CATEGORY = "/blog/getblogswithcategory";

//get all blog by category
export const API_GET_ALL_BLOG_BY_CATEGORY = "/blog/getallblogsbycategory";

//get blog details
export const API_GET_BLOG_DETAILS = "/blog/getblogdetails";

//get more bloglist
export const API_GET_MORE_BLOGS = "/blog/getmorebloglist";

/*====== blog url end ======*/

/*====== success story url start ======*/
//get all success story
export const API_GET_ALL_SUCCESS_STORIES =
  "/successstories/getallsuccessstories";

//get success story details
export const API_GET_SUCCESS_STORY_DETAILS =
  "/successstories/getsuccessstoriesdetails";

/*====== success story url end ======*/

/*====== event url start ======*/
//get all event type
export const API_GET_ALL_EVENT_TYPES = "/eventtype/getalleventtypes";

//get all event
export const API_GET_ALL_EVENTS = "/event/getallevents";

//get event list for select
export const API_GET_EVENT_LIST_OPTIONS = "/event/geteventlistforoptions";

//get event details
export const API_GET_EVENT_DETAILS = "/event/geteventdetails";

//get all event member
export const API_GET_ALL_EVENT_MEMBER = "/event/getallmembers";

//update event
export const API_UPDATE_EVENT = "/event/updateevent";

//join event
export const API_JOIN_EVENT = "/event/joinevent";

//add/remove tag
export const API_ADD_REMOVE_TAG_EVENT = "/event/addremovetagevent";

/*====== event url end ======*/

/*====== chat conversation begins ======*/
//get all event ---------------------------------- GPT AI ------
// -------------------------------------------------------------
export const API_CHAT_GPT_RESPONSE = "/chat/gpt"; // basic gpt

export const API_CHAT_GPT_PROMPTS = "/gptprompt/getprompts"; // gpt prompts for ecosystem

export const API_CHAT_GPT_ASSISTANT = "/chat/gpt/assistant"; // with assistant

export const API_CHAT_GPT_CV_ASSISTANT = "/chat/gpt/cvassistant"; // with CV assistant

export const API_CHAT_GPT_ASSISTANT_FILEUPLOAD = "/chat/gpt/fileassistant";

// ------------- GPT AI ends ------------------------------------
//send chat
export const API_SEND_CHAT = "/chat/sendchat";

//get all chats
export const API_GET_ALL_CHAT_MESSAGES = "/chat/getallchat";

//update chat
export const API_UPDATE_CHAT = "/chat/updatechat";

//deletechat
export const API_DELETE_CHAT = "/chat/deletechat";

//removerequireresponse
export const API_REMOVE_REQUIRE_RESPONSE = "/chat/removerequireresponse";

/*====== chat conversation ends ======*/
/*====== company url start ======*/
//get all company type
export const API_GET_ALL_COMPANY_TYPE = "/companytype/getallcompanytype";

//get all company
export const API_GET_ALL_COMPANY = "/company/getallcompanylist";

//getcompany by input search
export const API_GET_ALL_COMPANY_BY_SEARCH = "/company/getcompanyselectlist";

//get company details
export const API_GET_COMPANY_DETAILS = "/company/getcompanydetails";

//add/update tag company
export const API_ADD_REMOVE_TAG_COMPANY = "/company/addtagtocompany";

//get all member
export const API_GET_ALL_COMPANY_MEMBERS = "/company/getallcompanymemberlist";

/*====== company url end ======*/

/*===== experts/appuser url start ======*/

//get all app users
export const API_GET_APP_USERS = "/account/getallappusers";

//get app user details
export const API_GET_USER_DETAILS = "/account/userdetails";

//add/remove tag to app user
export const API_ADD_REMOVE_TAG_APP_USER = "/account/addtagtouser";

//send connect request
export const API_SEND_REQUEST_USER = "/contact/sendinvite";

/*===== experts/appuser url end ======*/

/*====== contact url start ======*/
//get all contact
export const API_GET_ALL_CONTACTS = "/contact/getallcontacts";

//get all connect invites
export const API_GET_ALL_RECIEVED_INVITE = "/contact/getallrecievedinvites";

//get all send invite list
export const API_GET_ALL_SEND_INVITE = "/contact/getallsendinvites";

//accept/reject invite
export const API_ACCEPT_INVITE = "/contact/updatecontact";

//cancel/reject request
export const API_REJECT_CONTACT_REQUEST = "/contact/rejectrequest";

//unfriend contact
export const API_UNFRIEND_CONTACT = "/contact/unfriendcontact";

//resume contact
export const API_RESUME_CONTACT = "/contact/resumecontact";

//get contact details
export const API_GET_CONTACT_DETAILS = "/contact/getcontactdetails";

/*====== contact url end ======*/

/*==== group url start =====*/
//create group
export const API_CREATE_GROUP = "/group/creategroup";

//get all group
export const API_GET_ALL_GROUPS = "/group/getallgroups";

//get group list for select
export const API_GET_GROUP_LIST = "/group/getgrouplist";

//get group details
export const API_GET_GROUP_DETAILS = "/group/getgroupdetails";

//update group
export const API_UPDATE_GROUP = "/group/updategroup";

//add/remove tag to group
export const API_ADD_REMOVE_TAG_GROUP = "/group/addremovetaggroup";

//join group
export const API_JOIN_GROUP = "/group/joingroup";

//get all members
export const API_GET_ALL_GROUP_MEMBER = "/group/getallmembers";

/*==== group url end =====*/

//search user for add member in event,group and activity
export const API_GET_USERLIST = "/account/getuserlist";

/*=== forum url start ==== */
//create forum
export const API_CREATE_FORUM = "/activityforum/createforum";

//like/dislike post
export const API_TOGGLE_LIKE_FORUM_POST = "/activityforum/togglelikepost";

//add comment
export const API_ADD_COMMENT = "/activityforum/addcomment";

//toggle like comment
export const API_TOGGLE_LIKE_FORUM_COMMENT =
  "/activityforum/togglelikeoncomment";

//get all forum
export const API_GET_ALL_FORUM = "/activityforum/getallforum";

//get details of forum
export const API_GET_FORUM_DETAILS = "/activityforum/forumdetails";

//get comment details
export const API_GET_COMMENT_DETAILS = "/activityforum/getcommentdetails";

//update forum
export const API_UPDATE_FORUM = "/activityforum/updateforum";

//update comment
export const API_UPDATE_COMMENT = "/activityforum/updatecomment";

//add tag forum
export const API_ADD_REMOVE_TAG_FORUM = "/activityforum/addremovetagforum";

//Cart Checkout Session Url, return session id for payment
export const API_CART_CHECKOUT_SESSION = "/checkout/session";

// Plans
export const API_GET_ALL_PLANS = "/plan/getall"; //get all plans

export const API_GET_PLAN_DETAILS = "/plan/getdetails";

export const API_CREATE_PLAN_SUBSCRIPTION = "/plan/createsubscription"; // create invoice by plan

// invoice
export const API_CREATE_INVOICE_BYPLAN = "/invoice/createbyplan"; // create invoice by plan

export const API_GET_INVOICE_DETAILS = "/invoice/getinvoicedetails";

// Payments
export const API_CREATE_PAYMENT_BY_INVOICE = "/payment/createbyinvoice"; // create invoice by plan
/*=== forum url end ==== */

/* challenge url start */
//create challenge
export const API_ADD_CHALLENGE = "/challenge/createchallenge";

//get all challenge
export const API_GET_ALL_CHALLENGE = "/challenge/getallchallenges";

//get details of challenge
export const API_GET_CHALLENGE_DETAILS = "/challenge/getchallengedetails";

//getchallengeresponsedetails
export const API_GET_CHALLENGE_RESPONSE_DETAILS = "/challenge/responsedetails";

// save customer feedback on challenge responses
export const API_ADD_CHALLENGE_RESPONSE_CUSTOMER_FEEDBACK =
  "/challenge/response/customerfeedback";
// get customer feedbacks on challenge responses
export const API_GET_CHALLENGE_RESPONSE_CUSTOMER_FEEDBACK =
  "/challenge/response/getfeedbacks";

//update challenge
export const API_UPDATE_CHALLENGE = "/challenge/updatechallenge";

//add label
export const API_ADD_LABEL_CHALLENGE = "/challenge/addremovetagchallenge";

//add new process
export const API_ADD_NEW_JOB_PROCESS = "/challenge/addnewjobprocess";

//getalljobprocess
export const API_GET_ALL_JOB_PROCESS = "/challenge/getalljobprocess";

//get process details
export const API_GET_JOB_PROCESS_DETAILS = "/challenge/getprocessdetails";

//update process
export const API_UPDATE_JOB_PROCESS = "/challenge/updatejobprocess";

//add new member
export const API_ADD_NEW_JOB_MEMBER = "/challenge/addchallengemember";

//get all member
export const API_GET_ALL_JOB_MEMBER = "/challenge/getallmembers";

//getjobmemberdetails
export const API_GET_JOB_MEMBER_DETAILS = "/challenge/getjobmemberdetails";

//updatechallengemember
export const API_UPDATE_JOB_MEMBER = "/challenge/updatechallengemember";

//addremovetagjobmemember
export const API_ADD_REMOVE_TAG_JOB_MEMBER =
  "/challenge/addremovetagjobmemember";
/* challenge url end */

//cvroutes

//create cv
export const API_CREATE_CV = "/cv/createcv";

//getallcv
export const API_GET_ALL_CV = "/cv/getallcv";

//getcvdetails
export const API_GET_CV_DETAILS = "/cv/getcvdetails";

//updatecv
export const API_UPDATE_CV = "/cv/updatecv";

//api get survey details
export const API_GET_SURVEY_INVITE_DETAILS = "/survey/getsurveyinvitedetails";

export const API_SAVE_RESPONSE_SURVEY = "/survey/addresponsetosurvey";

export const API_GET_SURVEY_RESPONSES = "/survey/getallsurveyresponses";

export const API_CREATE_RECOMENDATION = "/invoice/createrecomandation";

export const API_GROUP_OPPORTUNITIES = "/invoice/getgroupopportunities";

//course url start

//get all course
export const API_GET_ALL_COURSES = "/course/getallcourse";

//get course details
export const API_GET_COURSE_DETAILS = "/course/getcoursedetails";

//addremovetagcourse
export const API_ADD_REMOVE_TAG_COURSE = "/course/addremovetagcourse";

//getcourselessondetails
export const API_GET_COURSE_LESSON_DETAILS = "/course/getcourselessondetails";

//join course
export const API_JOIN_COURSE = "/course/joincourse";

//getexistinglessontask
export const API_GET_EXISTING_LESSON_TASK = "/course/getexistinglessontask";

//createlessonassignment
export const API_CREATE_LESSON_ASSIGNMENT = "/course/createlessonassignment";

//submitlessontask
export const API_SUBMIT_LESSON_TASK = "/course/submitlessontask";

//get all logs/answers
export const API_GET_COURSE_TASK_ANSWERS = "/course/getallsubmittedtasklogs";

//getallcourselessonfaqs
export const API_GET_ALL_COURSE_LESSON_FAQS = "/course/getallcourselessonfaqs";

//complete lesson
export const API_COMPLETE_COURSE_LESSON = "/course/completecourselesson";
//review url
//submit review
export const API_SUBMIT_REVIEW = "/review/givereview";

//get all reviews
export const API_GET_ALL_REVIEWS = "/review/getallreviews";

/*====== skillpoint url start ======*/
//add new skillpoint
export const API_CREATE_NEW_SKILLPOINT = "/skillpoint/addskillpoint";

//get skillpoint details
export const API_GET_SKILLPOINT_DETAILS = "/skillpoint/getskillpointdetails";

//update skillpoint
export const API_UPDATE_SKILLPOINT = "/skillpoint/updateskillpoint";

//delete skillpoint
export const API_DELETE_SKILLPOINT = "/skillpoint/deleteskillpoint";

/*====== skillpoint url end ======*/

/*===== task url ======*/
//get task details
export const API_GET_TASK_DETAILS = "/project/gettaskdetails";

//note url start
//add note
export const API_ADD_NEW_NOTE = "/note/createnote";

//get all note
export const API_GET_ALL_NOTE = "/note/getallnotes";

//get note details
export const API_GET_NOTE_DETAILS = "/note/getnotedetails";

//update note
export const API_UPDATE_NOTE = "/note/updatenote";

//delete note
export const API_DELETE_NOTE = "/note/deletenote";
//note url end
