import React from 'react';
import { Link } from 'react-router-dom';
import DetailsTab from './JobDetailsMiddleTabs/DetailsTab/DetailsTab';
import AppliedTab from './JobDetailsMiddleTabs/AppliedTab/AppliedTab';
import TeamsTab from './JobDetailsMiddleTabs/TeamsTab/TeamsTab';
import ForumTab from './JobDetailsMiddleTabs/ForumTab/ForumTab';
import DocsTab from './JobDetailsMiddleTabs/DocsTab/DocsTab';
import WinnerTab from './JobDetailsMiddleTabs/WinnerTab/WinnerTab';
import ProcessTab from './JobDetailsMiddleTabs/ProcessTab/ProcessTab';
import FAQTab from './JobDetailsMiddleTabs/FAQTab/FAQTab';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const JobDetailsMiddlePanel = ({
  requiredSkills,
  optionalSkills,
  summary,
  details,
  aboutPosition,
  aboutCompany,
  minSalary,
  maxSalary,
  uploadedDocFiles,
  memberList,
  processList,
  isApplied,
}) => {
  const { t } = useTranslation(); // translation marker
  return (
    <div className="pnl_mdlpnl">
      <div className="people_item">
        <div className="tab_links people_tablinks">
          <ul className="nav nav-tabs list_stye_none">
            <li className="nav-item">
              <Link
                className="nav-link active show"
                data-bs-toggle="tab"
                to="#overview"
              >
                {t('Details')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#forum">
                {t('Forum')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="#members">
                {t('Teams')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#applied">
                {t('Applied')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#process">
                {t('Process')}
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#winner">
                {t('Winner')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#faq">
                {t('FAQ')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#doc">
                {t('Docs')}
              </Link>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade show active" id="overview">
            <DetailsTab
              summary={summary}
              details={details}
              aboutPosition={aboutPosition}
              aboutCompany={aboutCompany}
              minSalary={minSalary}
              maxSalary={maxSalary}
              isApplied={isApplied}
            />
          </div>

          <div className="tab-pane fade" id="applied">
            <AppliedTab />
          </div>

          <div className="tab-pane fade" id="members">
            <TeamsTab memberList={memberList} />
          </div>

          <div className="tab-pane fade" id="forum">
            <ForumTab />
          </div>

          <div className="tab-pane fade" id="doc">
            <DocsTab uploadedDocFiles={uploadedDocFiles} />
          </div>

          <div className="tab-pane fade" id="winner">
            <WinnerTab />
          </div>

          <div className="tab-pane fade" id="process">
            <ProcessTab processList={processList} />
          </div>

          <div className="tab-pane fade" id="faq">
            <FAQTab />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsMiddlePanel;
