import React from 'react';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const HomeWorking = () => {
  const { t } = useTranslation(); // translation marker

  return (
    <section className="how_works_area pd_130">
      <div className="container">
        <div className="howwrks_outer">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="tab_leftpnl">
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="relationship">
                    <div className="tab_pic">
                      <img src="images/reletionship-model-tab.png" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="study">
                    <div className="tab_pic">
                      <img src="images/study.png" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="strategy">
                    <div className="tab_pic">
                      <img src="images/statregy.png" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="user_experience">
                    <div className="tab_pic">
                      <img src="images/user-experience.png" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="technical_solution">
                    <div className="tab_pic">
                      <img src="images/technical-solution.png" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="product_launch">
                    <div className="tab_pic">
                      <img src="images/product-launch.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5">
              <div className="tab_rightpnl">
                <div className="page_hdng">
                  <h5>{t('Work Process')}</h5>
                  <h2>
                    {t('How it')} <br /> {t('work')}
                  </h2>
                </div>
                <div className="tab_links">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#relationship"
                      >
                        <span>1</span>
                        {t('Relationship Model')}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#study"
                      >
                        <span>2</span>
                        {t('Market Study')}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#strategy"
                      >
                        {' '}
                        <span>3</span> {t('Strategy')}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#user_experience"
                      >
                        {' '}
                        <span>4</span> {t('User Experience')}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#technical_solution"
                      >
                        {' '}
                        <span>5</span> {t('Technical Solution')}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#product_launch"
                      >
                        {' '}
                        <span>6</span> {t('Product Launch')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeWorking;
