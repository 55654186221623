/* eslint-disable */
import "react-multi-carousel/lib/styles.css";

import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import GroupListPlaceHolder from "./PlaceHolder/GroupListPlaceHolder";
import GroupListCard from "./Card/GroupListCard";
import GroupListFilterPopup from "../Popup/GroupListFilterPopup";
import { assetImages } from "constants";

const GroupLandingMiddlePanel = ({ params }) => {
  const { t } = useTranslation(); // translation marker

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const glowCount = [1, 2, 3];

  const [tagList, setTagList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);

  const [filterRating, setFilterRating] = useState("");
  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterGroupTitle, setFilterGroupTitle] = useState("");
  const [filterGroupCategories, setfilterGroupCategories] = useState([]);
  const [filterGroupInterests, setfilterGroupInterests] = useState([]);
  const [filterGroupSearchings, setfilterGroupSearchings] = useState([]);
  const [filterGroupTags, setFilterGroupTags] = useState([]);
  const [filterGroupCity, setfilterGroupCity] = useState("");
  const [filterGroupCountry, setfilterGroupCountry] = useState("");

  //function for get all category
  const getAllCategories = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=GROUP`;

      const response = await getData(requestUrl);

      if (response.status) {
        setcategoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllSearchingInterest = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=skills`;

      const response = await getData(requestUrl);

      if (response.status) {
        setsearchingList(response.data);
        setinterestList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all lead records
  const getAllGroups = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_GROUPS + `?token=${token}`;

      if (filterGroupTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterGroupTitle}`;
      }
      if (filterGroupCity != "") {
        requestUrl = requestUrl + `&filtercity=${filterGroupCity}`;
      }
      if (filterGroupCountry != "") {
        requestUrl = requestUrl + `&filtercountry=${filterGroupCountry}`;
      }
      if (filterDateRange != "") {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }
      if (filterRating != "") {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }
      if (filterGroupTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterGroupTags}`;
      }
      if (filterGroupCategories.length > 0) {
        requestUrl = requestUrl + `&filtercategory=${filterGroupCategories}`;
      }
      if (filterGroupInterests.length > 0) {
        requestUrl = requestUrl + `&filterinterest=${filterGroupInterests}`;
      }
      if (filterGroupSearchings.length > 0) {
        requestUrl = requestUrl + `&filtersearchings=${filterGroupSearchings}`;
      }

      console.log("url of group list---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in group list------->", response);

      setIsLoading(false);
      if (response.status) {
        setGroupList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange("");
    setFilterGroupTitle("");
    setfilterGroupCity("");
    setfilterGroupCountry("");
    setFilterRating("");
    setFilterGroupTags([]);
    setfilterGroupCategories([]);
    setfilterGroupInterests([]);
    setfilterGroupSearchings([]);
  };

  const resetList = () => {
    getAllGroups("0", "10", null);
  };

  //use effect for params
  useEffect(() => {
    if (params?.sortby) {
      getAllGroups("0", "10", params?.sortby);
    }
  }, [params]);

  //use effect for filter search
  useEffect(() => {
    if (
      filterDateRange != "" ||
      filterGroupTitle != "" ||
      filterGroupCity != "" ||
      filterGroupCountry != "" ||
      filterRating != "" ||
      filterGroupCategories.length > 0 ||
      filterGroupInterests.length > 0 ||
      filterGroupSearchings.length > 0 ||
      filterGroupTags.length > 0
    ) {
      getAllGroups("0", "10", null);
    }
  }, [
    filterDateRange,
    filterGroupTitle,
    filterRating,
    filterGroupCategories,
    filterGroupInterests,
    filterGroupSearchings,
    filterGroupTags,
    filterGroupCity,
    filterGroupCountry,
  ]);

  useEffect(() => {
    getAllTags();
    getAllCategories();
    getAllSearchingInterest();
    getAllGroups("0", "10", null);
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_GROUP")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <div className="back_btn">
        <Link to="#">
          <i className="material-icons-outlined">arrow_back</i>
          <span>{t('Back')}</span>
        </Link>
      </div> */}
        <div className="post_item_outer">
          <div className="post_item">
            {isLoading ? (
              <div className="activity_innr">
                {glowCount.map((item, index) => {
                  return <GroupListPlaceHolder key={index} />;
                })}
              </div>
            ) : (
              <div className="activity_innr">
                {groupList.map((groupData, index) => {
                  return <GroupListCard key={index} groupData={groupData} />;
                })}
              </div>
            )}
          </div>
        </div>
        <GroupListFilterPopup
          tagList={tagList}
          categoryList={categoryList}
          searchingList={searchingList}
          interestList={interestList}
          setfilterGroupCategories={setfilterGroupCategories}
          setfilterGroupInterests={setfilterGroupInterests}
          setfilterGroupSearchings={setfilterGroupSearchings}
          setfilterGroupCity={setfilterGroupCity}
          setfilterGroupCountry={setfilterGroupCountry}
          setFilterDateRange={setFilterDateRange}
          setFilterGroupTags={setFilterGroupTags}
          setFilterGroupTitle={setFilterGroupTitle}
          setFilterRating={setFilterRating}
          resetList={resetList}
        />
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default GroupLandingMiddlePanel;
