import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const ConnectTalents = ({ autoScollContactNow, setSourceName }) => {
  const { t } = useTranslation(); // translation marker

  const contactNowHandler = () => {
    autoScollContactNow();
    setSourceName("Hire IT talent : Connect us");
  };

  return (
    <section className="how_works_area pd_130">
      <div className="container">
        <div className="howwrks_outer talent_outer">
          <div className="row">
            <div className="col-lg-7">
              <div className="tab_leftpnl">
                <div className="tab_pic">
                  <img src="images/talent.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="tab_rightpnl">
                <div className="page_hdng">
                  <h5>{t("For Companies")}</h5>
                  <h2>
                    {t("Want to")} <br /> {t("hire Best")} <br />{" "}
                    {t("IT Talent")}?
                  </h2>
                </div>
                <div className="talent_para">
                  <p>
                    {t(
                      "If you are a company to connect with the best IT professionals and specialists . We select them for you or we put our IT teams at your disposal."
                    )}
                  </p>
                </div>
                <div className="talent_btn">
                  <Link
                    to="#"
                    className="btn black_btn"
                    onClick={contactNowHandler}
                  >
                    {t("Connect Us")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectTalents;
