/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { assetImages } from "constants";
import { formatTimeAgo } from "utils/FormatTimeAgo";

import { io } from "socket.io-client";

const ConversationRightContactChatPanel = ({
  selectedContactUserId,
  selectedChatEmail,
  selectedChatLogo,
  selectedChatRoomId,
  reloadChatPanel = false,
  setreloadChatPanel = () => {},
  chatcomponentName,
  setchatcomponentName,
  chatComponentTitle,
  setchatComponentTitle,
}) => {
  // const socket = useRef(null);
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // assign useref hook for auto scrolling
  const messagesEndRef = useRef(null);

  const socket = io(url.SERVER_URL);

  // socket.on("connect", () => {
  //   console.log("Connected! Socket ID:", socket.id); // Access socket.id when the connection is established
  // });

  const [chatMessages, setChatMessages] = useState([]);

  const [messageText, setMessageText] = useState("");

  const [messageIndex, setmessageIndex] = useState(null);
  const [messageUpdating, setmessageUpdating] = useState(false);

  // function for scroll chat bottom behavior
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  //get all message
  const getAllChatMessages = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CHAT_MESSAGES +
        `?token=${token}&chatpersonid=${selectedContactUserId}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setChatMessages(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //send chat message
  const sendMessageHandler = async () => {
    if (
      selectedContactUserId &&
      selectedChatEmail !== "" &&
      messageText !== ""
    ) {
      try {
        let messageData = {
          contactid: selectedChatRoomId,
          sender: userInfo._id,
          receiver: selectedContactUserId,
          parent: null,
          sendermail: userInfo.email,
          receivermail: selectedChatEmail,
          description: messageText,
          moduleslug: "MOD_CONTACT",
          uploads: [],
          component: chatcomponentName,
          componenttitle: chatComponentTitle,
        };

        // console.log(messageData);

        resetChatBox();

        let requestUrl =
          url.API_BASE_URL + url.API_SEND_CHAT + `?token=${token}`;

        const response = await postData(requestUrl, messageData);

        // console.log(response);

        if (response.status) {
          // setChatMessages((prevMessages) => [...prevMessages, response.data]);
          socket.emit("sendmessage", {
            chatroomid: selectedChatRoomId,
            messageData: response.data,
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //update chat
  const updateChatMessageRequireResponse = async (chatId = null) => {
    try {
      setmessageUpdating(true);

      let chatData = {
        requireresponse: true,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_UPDATE_CHAT + `/${chatId}?token=${token}`;

      const response = await putData(requestUrl, chatData);

      setmessageUpdating(false);

      if (response.status) {
        // setChatMessages((prevMessages) => [...prevMessages, response.data]);
        socket.emit("replacemessage", {
          chatroomid: selectedChatRoomId,
          messageData: response.data,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //delete chat
  const deleteChatHandler = async (chatId = null) => {
    if (chatId) {
      try {
        let requestUrl =
          url.API_BASE_URL + url.API_DELETE_CHAT + `/${chatId}?token=${token}`;

        const response = await putData(requestUrl);

        if (response.status) {
          // refresh chat
          socket.emit("reloadchatlist", { chatroomid: selectedChatRoomId }); // Replace chatroomid with the actual room ID

          getAllChatMessages();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //reset chat box
  const resetChatBox = () => {
    setMessageText("");
    setmessageIndex(null);
  };

  const sendMessageByEnter = (e) => {
    if (e.key === "Enter") {
      sendMessageHandler();
    }
  };

  useEffect(() => {
    if (reloadChatPanel) {
      // refresh chat
      socket.emit("reloadchatlist", { chatroomid: selectedChatRoomId }); // Replace chatroomid with the actual room ID

      getAllChatMessages();
      setreloadChatPanel(false);
    }
  }, [reloadChatPanel, selectedChatRoomId]);

  useEffect(() => {
    if (
      selectedContactUserId &&
      selectedChatRoomId &&
      selectedChatEmail !== ""
    ) {
      // console.log(
      //   "selectedContactUserId in message panel",
      //   selectedContactUserId
      // );
      getAllChatMessages();
    }
  }, [selectedContactUserId, selectedChatRoomId, selectedChatEmail]);

  useEffect(() => {
    // Join the chatroom
    socket.emit("joinchat", selectedChatRoomId); // Replace chatroomid with the actual room ID

    // Listen for incoming messages
    socket.on("incomingmessage", (messageData) => {
      // console.log("messageData", messageData);
      setChatMessages((prevMessages) => [...prevMessages, messageData]);
    });

    // Listen for incoming replace messages
    socket.on("incomingreplacemessage", (messageData) => {
      // console.log("messageData", messageData);

      setChatMessages((prevMessages) => {
        // Find index of the message with updated data
        const index = prevMessages.findIndex(
          (message) => message._id.toString() === messageData._id.toString()
        );

        // Replace student record at the found index
        if (index !== -1) {
          return [
            ...prevMessages.slice(0, index),
            messageData,
            ...prevMessages.slice(index + 1),
          ];
        }

        // Return the previous state if the student was not found
        return prevMessages;
      });
    });

    //listen for refresh chat list
    socket.on("refreshchatlist", () => {
      getAllChatMessages();
    });

    return () => {
      if (selectedChatRoomId) {
        setmessageIndex(null);
        socket.emit("leavechat", selectedChatRoomId); // Leave the chatroom on component unmount
        socket.off("incomingmessage"); // Clean up event listener on unmount if needed
      }
    };
  }, [selectedChatRoomId, socket]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  return (
    <>
      {/* polulate the chat data */}
      <div className="chating_mdlPnl">
        <div className="chatting_innr pd_top_60">
          <div className="msg_outer msg_shareicon_info">
            {chatMessages.map((chatData, index) => {
              return (
                <div key={index}>
                  {/* reciever side */}
                  {chatData.receiver === userInfo._id ? (
                    <div className="msg_row reply_msgRow">
                      <div className="chat_infoLft">
                        <div className="msg_usr">
                          <img
                            src={
                              selectedChatLogo == ""
                                ? assetImages.defauultUser
                                : url.SERVER_URL + selectedChatLogo
                            }
                            alt=""
                          />
                        </div>
                        <div className="chat_info_timeOuter">
                          <h4>
                            <i className="material-icons-round">
                              question_answer
                            </i>{" "}
                            Chat
                          </h4>
                          <h5>{formatTimeAgo(chatData.updatedAt)}</h5>
                        </div>
                      </div>
                      <div className="chat_msgright">
                        <div className="msg_text dropdown">
                          <p>{chatData.description}</p>

                          {chatData.requireresponse ? (
                            <button
                              className="mt-2 btn-sm btn-danger"
                              style={{
                                cursor: "default",
                              }}
                            >
                              <span className="text-white">
                                Pending Response
                              </span>
                            </button>
                          ) : null}

                          <Link
                            to="#"
                            data-bs-toggle="dropdown"
                            className="chat_expand_arws"
                          >
                            <span className="material-icons-outlined">
                              {" "}
                              expand_more{" "}
                            </span>
                          </Link>

                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                to="#"
                                onClick={() => {
                                  deleteChatHandler(chatData._id);
                                }}
                              >
                                <span>Delete</span>{" "}
                              </Link>{" "}
                            </li>
                          </ul>

                          {/* {chatData.requireresponse ? (
                            <h6 className="text-danger fs-sm">
                              * Pending Response
                            </h6>
                          ) : null} */}
                        </div>

                        {/* uploads */}
                        {/* <div className="upload_picOuter">
                          <div className="upload_pic_item">
                            <figure>
                              <img src="images/pic1.png" alt="" />
                            </figure>
                          </div>
                          <div className="upload_pic_item multiple_media">
                            <figure>
                              <img src="images/pic1.png" alt="" />
                            </figure>
                            <div className="media_caption">
                              <Link to="#">5+</Link>
                            </div>
                          </div>
                        </div> */}

                        <div className="d-flex gap-2">
                          {chatData.component && chatData.component !== "" ? (
                            <p>
                              Component :{" "}
                              <span className="text-primary fs-sm">
                                {chatData.component}
                              </span>
                            </p>
                          ) : null}{" "}
                          {chatData.componenttitle &&
                          chatData.componenttitle !== "" ? (
                            <p>
                              Title :{" "}
                              <span className="text-secondary fs-sm">
                                {chatData.componenttitle}
                              </span>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="msg_row own_msgRow mb-4">
                      <div className="chat_infoLft">
                        <div className="chat_info_timeOuter">
                          <h4>
                            <i className="material-icons-round">
                              question_answer
                            </i>{" "}
                            chat
                          </h4>
                          <h5>
                            {formatTimeAgo(chatData.updatedAt)}
                            <span className="msg_status double_tick seen_tick">
                              <i className="material-icons-round"> done_all</i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="chat_msgright">
                        <div className="own_msgArea dropdown mb-2">
                          <p>{chatData.description}</p>

                          {chatData.requireresponse ? (
                            <button
                              className="mt-2 btn-sm btn-danger"
                              style={{
                                cursor: "default",
                              }}
                            >
                              <span className="text-white">
                                Pending Response
                              </span>
                            </button>
                          ) : null}

                          <Link
                            to="#"
                            data-bs-toggle="dropdown"
                            className="chat_expand_arws"
                          >
                            <span className="material-icons-outlined">
                              {" "}
                              expand_more{" "}
                            </span>
                          </Link>

                          <ul className="dropdown-menu">
                            <li>
                              {messageUpdating && messageIndex === index ? (
                                <div
                                  className="mx-2 spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setmessageIndex(index);
                                    updateChatMessageRequireResponse(
                                      chatData._id
                                    );
                                  }}
                                >
                                  <span>Require Response</span>
                                </Link>
                              )}{" "}
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={() => {
                                  deleteChatHandler(chatData._id);
                                }}
                              >
                                <span>Delete</span>{" "}
                              </Link>{" "}
                            </li>
                          </ul>
                        </div>

                        <div className="d-flex gap-2 justify-content-end">
                          {chatData.component && chatData.component !== "" ? (
                            <p>
                              Component :{" "}
                              <span className="text-primary fs-sm">
                                {chatData.component}
                              </span>
                            </p>
                          ) : null}{" "}
                          {chatData.componenttitle &&
                          chatData.componenttitle !== "" ? (
                            <p>
                              Title :{" "}
                              <span className="text-secondary fs-sm">
                                {chatData.componenttitle}
                              </span>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {/* ---- calling useref hook for scroll behaviour ----- */}
            <div ref={messagesEndRef} name="scrollRefDiv" />

            {/* chat from my contact person with uploads */}
            {/* <div className="msg_row reply_msgRow">
              <div className="chat_infoLft">
                <div className="msg_usr">
                  <img src="images/people1.png" alt="" />
                </div>
                <div className="chat_info_timeOuter">
                  <h4>
                    <i className="material-icons-round">question_answer</i> Chat
                  </h4>
                  <h5>Thursday 12:02am</h5>
                </div>
              </div>
              <div className="chat_msgright">
                <div className="msg_text">
                  <p>Cun’t wait for our online baking class tonight 😁</p>
                </div>
                <div className="upload_picOuter">
                  <div className="upload_pic_item">
                    <figure>
                      <img src="images/pic1.png" alt="" />
                    </figure>
                  </div>
                  <div className="upload_pic_item multiple_media">
                    <figure>
                      <img src="images/pic1.png" alt="" />
                    </figure>
                    <div className="media_caption">
                      <Link to="#">5+</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* chat from loggedin user or my message*/}
            {/* <div className="msg_row own_msgRow">
              <div className="chat_infoLft">
                <div className="chat_info_timeOuter">
                  <h4>
                    <i className="material-icons-round">question_answer</i> chat
                  </h4>
                  <h5>
                    Thursday 12:02am{" "}
                    <span className="msg_status double_tick seen_tick">
                      <i className="material-icons-round"> done_all</i>
                    </span>
                  </h5>
                </div>
              </div>
              <div className="chat_msgright">
                <div className="own_msgArea">
                  <p>I’m all prepared</p>
                </div>
                <div className="own_msgArea">
                  <p>
                    Be together whenever, with our free* all-in-one
                    communication app, complete with unlimited text, voice,
                    video calling and group video chat features. Make one for me
                    also
                  </p>
                </div>
              </div>
            </div> */}

            {/* when person is thinking or replying */}
            {/* <div className="msg_row reply_msgRow">
              <div className="chat_infoLft">
                <div className="msg_usr">
                  <img src="images/people1.png" alt="" />
                </div>
                <div className="chat_info_timeOuter">
                  <div className="chat_dots">
                    <img src="images/dots-loading.gif" alt="" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* chat message box */}
      <div className="chat_sendPnl">
        <div className="emoji_iconArea">
          <ul className="list_stye_none">
            <li>
              <Link to="#">
                <span className="material-icons-round">upload_file</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="send_textbx">
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              className="form-control"
              placeholder="Enter your message"
              onKeyDown={sendMessageByEnter}
            />
            <button
              type="button"
              className="chat_send_btn"
              onClick={sendMessageHandler}
            >
              <span className="material-icons-round">send</span>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ConversationRightContactChatPanel;
