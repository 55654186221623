import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const DeveloperLobeesTeam = ({ autoScollContactNow, setSourceName }) => {
  const { t } = useTranslation(); // translation marker

  const contactNowHandler = () => {
    autoScollContactNow();
    setSourceName("Devlopers : Hire lobees team");
  };

  return (
    <section className="searching_sec pd_130">
      <div className="container">
        <div className="howwrks_outer talent_outer">
          <div className="row">
            <div className="col-lg-5">
              <div className="tab_rightpnl">
                <div className="page_hdng">
                  <h5>{t("Lobees Team")}</h5>
                  <h2>{t("hire from Lobees team & Get Started")}</h2>
                </div>
                <div className="talent_para">
                  <p>
                    {t(
                      "We have a wide range of team members for all life cycle of project."
                    )}
                  </p>
                </div>
                <div className="talent_btn">
                  <Link
                    to="#"
                    className="btn black_btn"
                    onClick={contactNowHandler}
                  >
                    {t("Connect Us")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="tab_leftpnl">
                <div className="tab_pic">
                  <img src="images/develop-team.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeveloperLobeesTeam;
