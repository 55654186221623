import React from 'react';
import { Link } from 'react-router-dom';

const JobDetailsRightPanel = () => {
  return (
    <div className="pnl_rightpnl">
      <div className="white_shadow_bx">
        <div className="contact_header">
          <div className="post_hdng">
            <h4>Similar Jobs</h4>
          </div>
          <div className="contacts_srch_right"></div>
        </div>
        <div className="similar_postpnl">
          <div className="similar_post_row">
            <div className="groups_info">
              <div className="top_grp_info">
                <div className="experts_holdertext">
                  <div className="company_logotxt">
                    <span>
                      <img src="images/google.svg" alt="" />
                    </span>
                    <span>Google</span>
                  </div>
                  <h3>Blockchain Developer</h3>
                </div>
                <div className="bookmarks_grps">
                  <Link to="#">
                    <i className="material-icons-outlined">bookmark_border</i>
                  </Link>
                </div>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined ornge_icon">star</i>
                    <span className="rating">
                      4.5 <em>(32)</em>
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>Madrid, Spain</span>
                  </li>
                </ul>
              </div>
              <div className="job_hdng">
                <h5>$50k - $150K</h5>
              </div>
            </div>
          </div>

          <div className="similar_post_row">
            <div className="groups_info">
              <div className="top_grp_info">
                <div className="experts_holdertext">
                  <div className="company_logotxt">
                    <span>
                      <img src="images/google.svg" alt="" />
                    </span>
                    <span>Google</span>
                  </div>
                  <h3>Blockchain Developer</h3>
                </div>
                <div className="bookmarks_grps">
                  <Link to="#">
                    <i className="material-icons-outlined">bookmark_border</i>
                  </Link>
                </div>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined ornge_icon">star</i>
                    <span className="rating">
                      4.5 <em>(32)</em>
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>Madrid, Spain</span>
                  </li>
                </ul>
              </div>
              <div className="job_hdng">
                <h5>$50k - $150K</h5>
              </div>
            </div>
          </div>
          <div className="similar_post_row">
            <div className="groups_info">
              <div className="top_grp_info">
                <div className="experts_holdertext">
                  <div className="company_logotxt">
                    <span>
                      <img src="images/google.svg" alt="" />
                    </span>
                    <span>Google</span>
                  </div>
                  <h3>Blockchain Developer</h3>
                </div>
                <div className="bookmarks_grps">
                  <Link to="#">
                    <i className="material-icons-outlined">bookmark_border</i>
                  </Link>
                </div>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined ornge_icon">star</i>
                    <span className="rating">
                      4.5 <em>(32)</em>
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>Madrid, Spain</span>
                  </li>
                </ul>
              </div>
              <div className="job_hdng">
                <h5>$50k - $150K</h5>
              </div>
            </div>
          </div>
          <div className="similar_post_row">
            <div className="groups_info">
              <div className="top_grp_info">
                <div className="experts_holdertext">
                  <div className="company_logotxt">
                    <span>
                      <img src="images/google.svg" alt="" />
                    </span>
                    <span>Google</span>
                  </div>
                  <h3>Blockchain Developer</h3>
                </div>
                <div className="bookmarks_grps">
                  <Link to="#">
                    <i className="material-icons-outlined">bookmark_border</i>
                  </Link>
                </div>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined ornge_icon">star</i>
                    <span className="rating">
                      4.5 <em>(32)</em>
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>Madrid, Spain</span>
                  </li>
                </ul>
              </div>
              <div className="job_hdng">
                <h5>$50k - $150K</h5>
              </div>
            </div>
          </div>
          <div className="similar_post_row">
            <div className="groups_info">
              <div className="top_grp_info">
                <div className="experts_holdertext">
                  <div className="company_logotxt">
                    <span>
                      <img src="images/google.svg" alt="" />
                    </span>
                    <span>Google</span>
                  </div>
                  <h3>Blockchain Developer</h3>
                </div>
                <div className="bookmarks_grps">
                  <Link to="#">
                    <i className="material-icons-outlined">bookmark_border</i>
                  </Link>
                </div>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined ornge_icon">star</i>
                    <span className="rating">
                      4.5 <em>(32)</em>
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>Madrid, Spain</span>
                  </li>
                </ul>
              </div>
              <div className="job_hdng">
                <h5>$50k - $150K</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsRightPanel;
