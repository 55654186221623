import React from "react";

const ProcessTab = ({ processList }) => {
  return (
    <div className="white_shadow_bx people_bx">
      <div className="dates_pnlOuter">
        {/* <div className="dates_pnlItem complete_item">
          <span className="dates_nmbr">1</span>
          <div className="dates_hdng">
            <h4>HR Interview</h4>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined">place</i>
                <span>San Deago,CA USA</span>
              </li>
              <li>
                <i className="material-icons-outlined">event_note</i>
                <span>01/09/2021 - 30/09/2021</span>
              </li>
              <li>
                <i className="material-icons-outlined">person_outline</i>
                <span>15 Participants</span>
              </li>
            </ul>
          </div>
          <div className="dates_listing">
            <h3>KPIs for evaluation</h3>
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined"> done</i> Academic
                Qualification
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> Experience
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> MEAN / MERN
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> MongoDB / MySQL
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> PHP
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> Bootstrap
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> Git
              </li>
            </ul>
          </div>
        </div>
        <div className="dates_pnlItem">
          <span className="dates_nmbr">2</span>
          <div className="dates_hdng">
            <h4>Technical Interview</h4>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined">place</i>
                <span>San Deago,CA USA</span>
              </li>
              <li>
                <i className="material-icons-outlined">event_note</i>
                <span>01/09/2021 - 30/09/2021</span>
              </li>
              <li>
                <i className="material-icons-outlined">person_outline</i>
                <span>15 Participants</span>
              </li>
            </ul>
          </div>
          <div className="dates_listing">
            <h3>KPIs for evaluation</h3>
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined"> done</i> Academic
                Qualification
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> Experience
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> MEAN / MERN
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> MongoDB / MySQL
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> PHP
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> Bootstrap
              </li>
              <li>
                <i className="material-icons-outlined"> done</i> Git
              </li>
            </ul>
          </div>
        </div>
        <div className="dates_pnlItem">
          <span className="dates_nmbr">3</span>
          <div className="dates_hdng">
            <h4>Document Verification</h4>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined">place</i>
                <span>San Deago,CA USA</span>
              </li>
              <li>
                <i className="material-icons-outlined">event_note</i>
                <span>01/09/2021 - 30/09/2021</span>
              </li>
              <li>
                <i className="material-icons-outlined">person_outline</i>
                <span>15 Participants</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="dates_pnlItem">
          <span className="dates_nmbr">4</span>
          <div className="dates_hdng">
            <h4>Joining</h4>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined">place</i>
                <span>San Deago,CA USA</span>
              </li>
              <li>
                <i className="material-icons-outlined">event_note</i>
                <span>01/09/2021 - 30/09/2021</span>
              </li>
              <li>
                <i className="material-icons-outlined">person_outline</i>
                <span>15 Participants</span>
              </li>
            </ul>
          </div>
        </div> */}
        {processList.map((process, index) => {
          return (
            <div className="dates_pnlItem" key={index}>
              <span className="dates_nmbr">{process.order}</span>
              <div className="dates_hdng">
                <h4>{process.title}</h4>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>San Deago,CA USA</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">event_note</i>
                    <span>
                      {process.startdate} - {process.enddate}
                    </span>
                  </li>
                  {/* <li>
                    <i className="material-icons-outlined">person_outline</i>
                    <span>15 Participants</span>
                  </li> */}
                </ul>
              </div>
              <div className="dates_listing">
                <h3>KPIs for evaluation</h3>
                <ul className="list_stye_none">
                  {process.kpisarr.map((kpis, index2) => {
                    return (
                      <li key={index2}>
                        <i className="material-icons-outlined"> done</i>{" "}
                        {kpis.skillname}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProcessTab;
