/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CreateNewGroupModal = ({ myContacts }) => {
  const [searchValue, setSearchValue] = useState("");

  //function for set filter leads
  const filterSearchContacts = () => {
    return myContacts.filter((contact) => {
      const searchTerm = searchValue.toLowerCase().trim();
      const nameMatch = contact.contactname.toLowerCase().includes(searchTerm);

      return nameMatch;
    });
  };

  return (
    <div className="chat_modalbx">
      <div className="modal fade" id="group_people_mdl">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              {/* <!-- <h5 className="modal-title">Add to Group</h5>
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span className="material-icons-round">close </span>
                  </button> --> */}
              <div className="enter_group_name">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Group Name"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="group_srchfill">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for people"
                />
              </div>
              <div className="group_innrScroll">
                {myContacts.map((contact, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        filterSearchContacts().includes(contact)
                          ? "chat_usrRow cursor-pointer"
                          : "d-none"
                      }
                    >
                      <Link to="#">
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            <img src={contact.contactimage} alt="" />
                          </div>
                          <div className="user_name">
                            <h3>{contact.contactname}</h3>
                          </div>
                        </div>
                        {/* <div className="members_check blue_bullet">
                      <span></span>
                    </div> */}
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className="group_btnOuter">
                <ul>
                  <li className="w_100">
                    <a
                      href="add_chatuser.html"
                      className="ornage_btn"
                      data-bs-dismiss="modal"
                    >
                      <i className="material-icons-round">check_circle</i>
                      <span>Done</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewGroupModal;
