/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import * as url from 'helper/UrlHelper';
import { assetImages } from 'constants';
import { getPostDateFormat } from 'helper/ChallengeHelper/ChallengeHelper';

const JobListCard = ({ job, onJobSelect }) => {
  return (
    <div
      className="groups_item jobs_item"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onJobSelect(job._id);
      }}
    >
      <div className="top_company_row">
        <div className="company_logotxt">
          <span>
            <img
              className="rounded-circle"
              src={
                job.companylogopath == ''
                  ? assetImages.defaultUser
                  : url.SERVER_URL + job.companylogopath
              }
              alt=""
              height={35}
            />
          </span>
          <span className="ms-2">{job.companynamestring}</span>
        </div>
        <div className="rating_tags d-flex align-items-center">
          <div className="jb_rating">
            <i className="material-icons-outlined ornge_icon">star</i>
            <span>
              {job.ratingtotal} <em>({job.reviewtotal})</em>
            </span>
          </div>
          <div className="tags_icon">
            <Link to="#">
              <span className="material-icons-outlined">bookmark_border</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="event_hdng">
        <h3>{job.name}</h3>
        <h4>
          €{job.minsalary} - €{job.maxsalary}
        </h4>
        <h5>
          <i className="material-icons-outlined"> event_note </i>
          <span>{job.createddate}</span>
        </h5>
      </div>
      <div className="event_tags">
        <ul className="list_stye_none">
          <li>
            <i className="material-icons-outlined"> schedule</i>
            <span>{job.jobtype}</span>
          </li>
          <li>
            <i className="material-icons-outlined"> place </i>
            <span className="">{job.city}</span>
          </li>

          <li>
            <i className="material-icons-outlined"> place</i>
            <span>{job.country.slice(0, 7)}</span>
          </li>
        </ul>
      </div>
      <div className="event_para">
        <p>{job.summary.slice(0, 100)}</p>
      </div>
    </div>
  );
};

export default JobListCard;
