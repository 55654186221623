/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect } from "react";

import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";
import JobLandingBody from "components/JobsComponents/JobLandingComponents/JobLandingBody/JobLandingBody";

const JobLanding = () => {
  const { t, i18n } = useTranslation(); // translation marker

  return (
    <>
      {/* ------- header section ---------- */}
      <header className="aftre_login_header">
        <AuthHeader />

        <div className="header_bottom_menu">
          <span className="cross_btn material-icons-outlined d-none">
            close
          </span>
          <div className="container-fluid">
            <div className="bottom_menu_list activity_top_menu">
              <ul className="list_stye_none">
                <li className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className="active_menu"
                  >
                    <span className="">
                      <span>{t("Activities")}</span>
                      <i className="material-icons-outlined down_arw">
                        expand_more
                      </i>
                    </span>
                  </Link>
                  <ul className="list_stye_none dropdown-menu">
                    <li>
                      <Link to="/peoples">{t("People")}</Link>
                    </li>
                    <li>
                      <Link to="groups.html">{t("Groups")}</Link>
                    </li>
                    <li>
                      <Link to="events.html">{t("Events")}</Link>
                    </li>
                    <li>
                      <Link to="course.html">{t("Courses")}</Link>
                    </li>
                    <li>
                      <Link to="job.html">{t("Jobs")}</Link>
                    </li>
                    <li>
                      <Link to="company.html">{t("Company")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className="active_menu"
                  >
                    <span className="">
                      <span>{t("Sort by")} </span>
                      <i className="material-icons-outlined down_arw">
                        expand_more
                      </i>
                    </span>
                  </Link>
                  <ul className="list_stye_none dropdown-menu">
                    <li>
                      <Link to="/peoples">{t("Rank")} UP -1</Link>
                    </li>
                    <li>
                      <Link to="groups.html">{t("Rank")} DOWN +1</Link>
                    </li>
                    <li>
                      <Link to="events.html">{t("Name")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <span className="">
                      <span>{t("New Registered")}</span>
                      <i className="material-icons-outlined down_arw">
                        expand_more
                      </i>
                    </span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="#appuser_filter_offcanvas"
                    data-bs-toggle="offcanvas"
                    role="button"
                    aria-controls="appuser_filter_offcanvas"
                  >
                    <span className="">
                      <span>{t("All filters")}</span>
                      <i className="material-icons-outlined down_arw">
                        navigate_next
                      </i>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      {/* --------- body section ------------ */}
      <JobLandingBody />
    </>
  );
};

export default JobLanding;
