/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import ContactModal from "../Modals/ContactModal";
import GroupModal from "../Modals/GroupModal";
import CreateNewGroupModal from "../Modals/CreateNewGroupModal";

const ConversationLeftPanel = ({
  updateDefaultScreen,
  updateSelectedChat,
  updateConversationName,
  updateSelectedChatLogo,
  updateSelectedChatEmail,
  updatedSelectedChatRoom,
  updatedSelectedChatDetails,
  setchatcomponentName = () => {},
  setchatComponentTitle = () => {},
  setreloadChatPanel = () => {},
}) => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); // translation marker

  const [contactList, setContactList] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [showChatPopup, setShowChatPopup] = useState(false);

  const [selectedChatParams, setselectedChatParams] = useState(null);

  const [selectedChatContactId, setselectedChatContactId] = useState(null);
  const [selectedChatIndex, setSelectedChatIndex] = useState(null);
  const [isClosingResponse, setisClosingResponse] = useState(false);

  //select chat
  const chatSelectionHandler = (
    conversationId,
    conversationName,
    conversationLogo,
    conversationEmail,
    screen = "contact",
    conversationChatId,
    conversationChatPhone,
    conversationChatAddress,
    conversationChatCompany,
    componentName = "",
    componentTitle = ""
  ) => {
    document.body.classList.toggle("chatmsg_open");
    updateDefaultScreen(screen);
    updateSelectedChat(conversationId);
    updateConversationName(conversationName);
    updateSelectedChatLogo(conversationLogo);
    updateSelectedChatEmail(conversationEmail);
    updatedSelectedChatRoom(conversationChatId);
    updatedSelectedChatDetails({
      conversationChatPhone,
      conversationChatAddress,
      conversationChatCompany,
    });

    if (componentName !== "") {
      setchatcomponentName(componentName);
    } else {
      setchatcomponentName("");
    }

    if (componentTitle !== "") {
      setchatComponentTitle(componentTitle);
    } else {
      setchatComponentTitle("");
    }
  };

  //function for get all contacts
  const getAllContactList = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_CONTACTS + `?token=${token}`;

      // console.log("url of contact list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in contact list------>", response);

      if (response.status) {
        const filteredChats = response.data
          .filter((chat) => chat.lastmessageid) // Filter based on 'lastmessageid' property
          .sort((a, b) => {
            const dateA = new Date(a.lastmessagetimestamp);
            const dateB = new Date(b.lastmessagetimestamp);
            return dateB - dateA; // Sort in descending order; use dateA - dateB for ascending
          });

        setContactList(filteredChats);

        setAllContacts(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for remove require response
  const removeRequireResponseHandler = async (
    contact = {},
    moduleName = "contact"
  ) => {
    try {
      setisClosingResponse(true);

      let chatData = {
        chatpersonid: contact.userid,
      };

      let requestURL =
        url.API_BASE_URL + url.API_REMOVE_REQUIRE_RESPONSE + `?token=${token}`;

      const response = await putData(requestURL, chatData);

      console.log(response);

      setisClosingResponse(false);

      if (response.status) {
        if (selectedChatContactId && selectedChatContactId === contact._id) {
          setreloadChatPanel(true);
        }

        getAllContactList();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get module details
  const getModuleDetails = async (
    conversationType = "",
    conversationId = null,
    moduleSlug = "",
    componentName = "",
    componentTitle = ""
  ) => {
    if (conversationType !== "" && moduleSlug !== "" && conversationId) {
      try {
        let requestUrl = url.API_BASE_URL;

        if (moduleSlug === "MOD_CONTACT") {
          requestUrl += `${url.API_GET_CONTACT_DETAILS}/${conversationId}?token=${token}`;

          const response = await getData(requestUrl);

          // console.log(response);

          if (response.status) {
            const data = response.data;

            // chatSelectionHandler(
            //   data.contactpersonid,
            //   data.contactname,
            //   data.contactimage,
            //   data.contactemail,
            //   conversationType,
            //   data._id,
            //   data.contactphone,
            //   data.contactcity + ", " + data.contactcountry,
            //   data.contactcompany
            //   componentName,
            //   componentTitle
            // );

            setselectedChatParams({
              contactpersonid: data.contactpersonid,
              contactname: data.contactname,
              contactimage: data.contactimage,
              contactemail: data.contactemail,
              conversationType,
              id: data._id,
              contactphone: data.contactphone,
              address: data.contactcity + ", " + data.contactcountry,
              contactcompany: data.contactcompany,
              componentName,
              componentTitle,
            });
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    if (selectedChatParams) {
      chatSelectionHandler(
        selectedChatParams.contactpersonid,
        selectedChatParams.contactname,
        selectedChatParams.contactimage,
        selectedChatParams.contactemail,
        selectedChatParams.conversationType,
        selectedChatParams.id,
        selectedChatParams.contactphone,
        selectedChatParams.address,
        selectedChatParams.contactcompany,
        selectedChatParams.componentName,
        selectedChatParams.componentTitle
      );
      setselectedChatParams(null);
    }
  }, [selectedChatParams]);

  useEffect(() => {
    setselectedChatContactId(null);
    setchatcomponentName("");
    setchatComponentTitle("");
    getAllContactList();

    if (
      params.conversationtype ||
      params.conversationid ||
      params.moduleslug ||
      params.componentname ||
      params.componenttitle
    ) {
      getModuleDetails(
        params.conversationtype,
        params.conversationid,
        params.moduleslug,
        params.componentname,
        params.componenttitle
      );
    }
  }, []);

  return (
    <div className="chat_lftpnl">
      <div className="contact_header">
        <div className="contact_hdng_row d-flex align-items-center justify-content-between">
          <div className="post_hdng">
            <h4>Contacts</h4>
          </div>
          <div className="contacts_srch_right">
            <ul className="list_stye_none d-flex align-items-center gap-2">
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">search</i>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">more_vert</i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="contacts_pnl">
        <div className="chat_usrPnl">
          <div className="chat_innrScroll">
            <div className="ai_assistant">
              <div className="chat_hdng">
                <span>AI Assistant</span>
              </div>
              <div className="chat_usrRow send_msg_row active_user">
                <Link
                  to="#"
                  onClick={() => {
                    chatSelectionHandler(
                      "0",
                      "",
                      "",
                      "",
                      "ai",
                      null,
                      "",
                      "",
                      "",
                      "",
                      ""
                    );
                  }}
                >
                  <div className="usr_lftPnl">
                    <div className="chatting_user">
                      <img src="images/ai-logo.svg" alt="" />
                    </div>
                    <div className="user_name">
                      <h3>LobeesAI</h3>
                      {/* <p>I’m looking forward</p> */}
                    </div>
                  </div>
                  <div className="msg_statusRight">
                    {/* <span className="msg_time">now</span> */}
                    {/* <span className="pending_msg">5</span> */}
                  </div>
                </Link>
              </div>
            </div>

            <div className="left_msgbody">
              <div className="chat_hdng">
                <span>My Contacts</span>
              </div>
              {contactList.map((contact, index) => {
                return (
                  <div className="chat_usrRow send_msg_row " key={index}>
                    <div className="d-flex align-items-center justify-content-between py-2 px-3 chat_user_item">
                      <div
                        className="usr_lftPnl"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setselectedChatContactId(contact._id);
                          chatSelectionHandler(
                            contact.userid,
                            contact.contactname,
                            contact.contactimage,
                            contact.contactemail,
                            "contact",
                            contact._id,
                            contact.contactphone,
                            contact.contactcity + ", " + contact.contactcountry,
                            contact.contactcompany,
                            "",
                            ""
                          );
                        }}
                      >
                        <div className="chatting_user">
                          <img
                            src={
                              contact.contactimage === ""
                                ? assetImages.defaultUser
                                : url.SERVER_URL + contact.contactimage
                            }
                            alt=""
                          />
                        </div>
                        <div className="user_name">
                          <h3>{contact.contactname}</h3>
                          <p>
                            {contact.lastmessagesender.toString() ==
                            userInfo._id.toString() ? (
                              <span>You : </span>
                            ) : null}
                            {contact.lastmessagetext == ""
                              ? ""
                              : contact.lastmessagetext.slice(0, 25)}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="chat_option_right d-flex align-items-center gap-2">
                        <div className="msg_statusRight">
                          <span className="msg_time">
                            {contact.lastmessagetimestring}
                          </span>
                          <span className="msg_status">
                            <i className="material-icons-round">done_all</i>
                          </span>
                        </div>
                        <div className="option_more dropdown">
                          <Link
                            to="#"
                            className="p-0 more_dots"
                            data-bs-toggle="dropdown"
                          >
                            <i className="material-icons-outlined">more_vert</i>
                          </Link>
                          <ul className="list_stye_none dropdown-menu">
                            <li>
                              {selectedChatIndex === index &&
                              isClosingResponse ? (
                                <div
                                  className="mx-2 spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <Link
                                  to="#"
                                  className=""
                                  onClick={() => {
                                    setSelectedChatIndex(index);
                                    removeRequireResponseHandler(contact);
                                  }}
                                >
                                  Close Response
                                </Link>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className={
              showChatPopup ? "chat_cmntBx show_chatpopup" : "chat_cmntBx"
            }
          >
            <Link
              to="#"
              className="chat_btn"
              onClick={() => {
                setShowChatPopup(!showChatPopup);
              }}
            >
              <span className="material-icons-round">add_comment</span>
            </Link>
            <div className="lft_cht_popup">
              <ul>
                {/* create new chat */}
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#start_newchat"
                    onClick={() => {
                      setShowChatPopup(!showChatPopup);
                    }}
                  >
                    <i className="material-icons-round">person</i>
                    <span>New Chat</span>
                  </Link>
                </li>
                {/* create group modal */}
                {/* <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#group_modal"
                    onClick={() => {
                      setShowChatPopup(!showChatPopup);
                    }}
                  >
                    <i className="material-icons-round">people</i>
                    <span>New Group</span>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ContactModal
        myContacts={allContacts}
        handleConnectSelect={chatSelectionHandler}
      />
      <GroupModal />
      <CreateNewGroupModal myContacts={allContacts} />
    </div>
  );
};

export default ConversationLeftPanel;
