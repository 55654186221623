import React from 'react';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const SuccessStoriesLandingBanner = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section
      className="banner_area seccess_bnr"
      style={{ backgroundImage: 'url(images/banner-dots.png)' }}
    >
      <div className="container">
        <div className="banner_outer">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content">
                <h1>
                  {t('make')}{' '}
                  <span>
                    {t('success')} <br /> {t('stories')}
                  </span>{' '}
                  {t('with Lobees')}
                </h1>
                <p>
                  {t(
                    'Tools that enables an Ecosystem to Digitize your business opportunities and processes'
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStoriesLandingBanner;
