/* eslint-disable */
import React, { useEffect, useState } from "react";
import Header from "../../../components/common/Header";
import ContactNow from "components/common/ContactNow";
import Footer from "components/common/Footer";
import SuccessStoriesLandingBanner from "components/SuccessStoriesComponents/SuccessStoriesLandingComponents/SuccessStoriesLandingBanner/SuccessStoriesLandingBanner";
import SuccessStoriesLandingTestimonials from "components/SuccessStoriesComponents/SuccessStoriesLandingComponents/SuccessStoriesLandingTestimonials/SuccessStoriesLandingTestimonials";
import SuccessStoriesLandingList from "components/SuccessStoriesComponents/SuccessStoriesLandingComponents/SuccessStoriesLandingList/SuccessStoriesLandingList";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

const SuccessStoriesLanding = () => {
  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);
  const [storyList, setStoryList] = useState([]);

  //function for get all success stories
  const getAllSuccessStories = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_SUCCESS_STORIES + `?token=${token}`;

      console.log("url of all success story ------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("success story list response------>", response);

      if (response.status) {
        setIsLoading(false);
        setStoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    document.title = "Success Stories"; // Update the title when Register component mounts
    getAllSuccessStories();
  }, []);

  return (
    <>
      {/* ---- Header section start----- */}
      <Header />
      {/* ---- Header section end----- */}

      {/* --- footer start --- */}
      <SuccessStoriesLandingBanner />
      {/* --- footer end --- */}

      {/* --- reward section start --- */}
      <SuccessStoriesLandingTestimonials storyList={storyList} />
      {/* --- reward section end --- */}

      {/* ---- success stories list start --- */}
      <SuccessStoriesLandingList storyList={storyList} />
      {/* ---- success stories list end --- */}

      {/* --- contact us start --- */}
      <ContactNow />
      {/* --- contact us end --- */}

      {/* --- footer start ---- */}
      <Footer />
      {/* --- footer end ---- */}
    </>
  );
};

export default SuccessStoriesLanding;
