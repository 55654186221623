import React from 'react';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const EntrepreneursHowWeDo = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section className="we_doSec colaboarte_plansec pd_130">
      <div className="container">
        <div className="page_hdng dark_grnheading text-center">
          <h5>{t('This is how we work')}</h5>
          <h2>
            {t('Our plan in 3 steps')}, <br /> {t('The process is very')} <br />{' '}
            {t('simple')}
          </h2>
        </div>
        <div className="offer_col_outer">
          <div className="ofr_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="ofr_pic">
                  <img src="images/process-pic1.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ofr_textpnl">
                  <div className="ofr_icon blue_circle">
                    <span className="material-icons-outlined">videocam</span>
                  </div>
                  <div className="ofr_hdng">
                    <h3>{t('Email marketing')}</h3>
                  </div>
                  <div className="ofr_para">
                    <p>
                      {t(
                        'You tell us what you need and we propose a meeting by video conference in which we explain in detail how we can help you. We show you our system inside with examples of processes.'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ofr_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="ofr_pic">
                  <img src="images/process-pic2.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ofr_textpnl">
                  <div className="ofr_icon orange_circle">
                    <span className="material-icons-outlined">
                      person_search
                    </span>
                  </div>
                  <div className="ofr_hdng">
                    <h3>{t('Creating GACs')}</h3>
                  </div>
                  <div className="ofr_para">
                    <p>
                      {t(
                        "Depending on the chosen proposal, we present the groups and people with whom you are going to collaborate, we explain the work methodology and we give you access to all the tools of the Ecosystem. It's time to get to work"
                      )}
                      ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ofr_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="ofr_pic">
                  <img src="images/process-pic3.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ofr_textpnl">
                  <div className="ofr_icon ylw_circle">
                    <span className="material-icons-outlined">query_stats</span>
                  </div>
                  <div className="ofr_hdng">
                    <h3>
                      {t('Launching')} <br /> {t('Projects')}
                    </h3>
                  </div>
                  <div className="ofr_para">
                    <p>
                      {t(
                        'In Lobees we analyze everything in order to apply a process of continuous improvement in the Ecosystem and in each of the products and services of our associates. We help you improve with customer feedback and if you need collaboration in some of your processes we present professionals with all the guarantees.'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EntrepreneursHowWeDo;
