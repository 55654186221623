/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";
import CourseListPlaceHolder from "./PlaceHolder/CourseListPlaceHolder";
import CourseListCard from "./Card/CourseListCard";

const CourseLandingMiddlePanel = () => {
  const { t } = useTranslation(); // translation marker
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const placeHolderCount = [1, 2];

  const [isLoading, setIsLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);

  //get course list
  const getAllCourses = async () => {
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_COURSES + `?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      setIsLoading(false);

      if (response.status) {
        setCourseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COURSE")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <div className="back_btn">
        <Link to="#">
          <i className="material-icons-outlined">arrow_back</i>
          <span>{t('Back')}</span>
        </Link>
      </div> */}
        <div className="post_item_outer">
          <div className="post_item">
            {isLoading ? (
              <div className="activity_innr">
                {placeHolderCount.map((count, index) => {
                  return <CourseListPlaceHolder key={index} />;
                })}
              </div>
            ) : courseList.length === 0 ? (
              <div className="empty_access text-center">
                <div className="empty_pic mb-4">
                  {" "}
                  <img src={assetImages.emptyVector} alt="" />
                </div>
                <div className="empty_text">
                  <p className="fs-lg text-gray fw-semibold mb-4">
                    Sorry....! No course available at this moment
                  </p>
                </div>
              </div>
            ) : (
              <div className="activity_innr">
                {courseList.map((courseData, index) => {
                  return <CourseListCard courseData={courseData} key={index} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default CourseLandingMiddlePanel;
