import React from 'react';

const DocsTab = ({ uploadedDocFiles }) => {
  return (
    <div className="people_details_bx white_shadow_bx">
      <div className="upload_area">
        {uploadedDocFiles.map((file, index) => {
          return (
            <div className="upload_item" key={index}>
              <div className="uploaded_innrBx">
                <div className="upload_icon">
                  <img src="/images/pdf.svg" alt="" />
                </div>
                <div className="upload_txt">
                  <h4>{file.name}</h4>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocsTab;
