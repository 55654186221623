/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const GroupModal = ({ myGroups }) => {
  return (
    <div className="chat_modalbx">
      <div className="modal fade" id="group_modal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">Add to Group</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-round">close </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="group_srchfill">
                <input
                  type="text"
                  // value=""
                  className="form-control"
                  placeholder="Search for group"
                />
              </div>
              <div className="group_innrScroll">
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>UX Team - DEL</h3>
                        <p>Monalisa, Devid, Harry</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Design Team 2</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Management Team</h3>
                        <p>Asana, Sankar, Lisa</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <span className="group_usr plus_usr">
                          5 <em>+</em>
                        </span>
                      </div>
                      <div className="user_name">
                        <h3>XenTec Clients</h3>
                        <p>Monalisa, Devid, Lisa, and 5 more</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/dshbrd-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Dashboard SportsMoon</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>UX Team - DEL</h3>
                        <p>Monalisa, Devid, Harry</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Design Team 2</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Management Team</h3>
                        <p>Asana, Sankar, Lisa</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <span className="group_usr plus_usr">
                          5 <em>+</em>
                        </span>
                      </div>
                      <div className="user_name">
                        <h3>XenTec Clients</h3>
                        <p>Monalisa, Devid, Lisa, and 5 more</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/dshbrd-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Dashboard SportsMoon</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>UX Team - DEL</h3>
                        <p>Monalisa, Devid, Harry</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Design Team 2</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Management Team</h3>
                        <p>Asana, Sankar, Lisa</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <span className="group_usr plus_usr">
                          5 <em>+</em>
                        </span>
                      </div>
                      <div className="user_name">
                        <h3>XenTec Clients</h3>
                        <p>Monalisa, Devid, Lisa, and 5 more</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/dshbrd-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Dashboard SportsMoon</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>UX Team - DEL</h3>
                        <p>Monalisa, Devid, Harry</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Design Team 2</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Management Team</h3>
                        <p>Asana, Sankar, Lisa</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <span className="group_usr plus_usr">
                          5 <em>+</em>
                        </span>
                      </div>
                      <div className="user_name">
                        <h3>XenTec Clients</h3>
                        <p>Monalisa, Devid, Lisa, and 5 more</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/dshbrd-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Dashboard SportsMoon</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="group_btnOuter">
                <ul>
                  <li className="w_50">
                    <Link
                      to="#"
                      className="blue_btn"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                      data-bs-target="#group_people_mdl"
                    >
                      <i className="material-icons-round">add_circle</i>
                      <span>Create New</span>
                    </Link>
                  </li>
                  <li className="w_50">
                    <Link to="#" className="ornage_btn">
                      <i className="material-icons-round">check_circle</i>
                      <span>Done</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupModal;
