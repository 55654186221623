/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";

import ConversationRightContactChatPanel from "./ConversationRightContactChatPanel";
import { assetImages } from "constants";
import ConversationProfileDetailsPopup from "components/ConversationComponents/Popups/ConversationProfileDetailsPopup";

//import ConversationRightContactMailPanel from './ConversationRightContactMailPanel';

const ConversationRightContactPanel = ({
  selectedContactUserId,
  conversationName,
  selectedChatLogo,
  selectedChatEmail,
  selectedChatRoomId,
  selectedChatPhone,
  selectedChatAddress,
  selectedChatCompany,
  reloadChatPanel = false,
  setreloadChatPanel,
  chatcomponentName,
  setchatcomponentName,
  chatComponentTitle,
  setchatComponentTitle,
}) => {
  const mobileCloseChatHandler = () => {
    document.body.classList.toggle("chatmsg_open");
  };

  return (
    <>
      <div className="chat_topPnl">
        <div className="chatusr_info">
          <div className="list_back">
            <Link to="#" onClick={mobileCloseChatHandler}>
              <i className="material-icons-round">arrow_back</i>
            </Link>
          </div>
          <Link
            to="#"
            data-bs-toggle="offcanvas"
            data-bs-target="#profile_pnl"
            className="chat_usrOuter profile_linkBtn"
          >
            <div className="chat_usrPic">
              <img
                src={
                  selectedChatLogo == ""
                    ? assetImages.defaultUser
                    : url.SERVER_URL + selectedChatLogo
                }
                alt=""
              />
            </div>
            <div className="chat_usrtext">
              <h3>{conversationName}</h3>
              {/* <h5>Online 5 min ago</h5> */}
            </div>
          </Link>
          <div className="info_icons">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <i className="material-icons-round">search</i>
                </Link>
              </li>
              {/* <li>
                <Link to="#">
                  <i className="material-icons-round">person_add</i>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>

        <div className="chat_tablinks">
          <ul className="list_stye_none">
            <li className="active">
              <Link to="#">
                <i className="material-icons-round">question_answer</i>
                <span>chat</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#emaildetails_mdl"
              >
                <i className="material-icons-round">email</i>
                <span>mail</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>

      <ConversationRightContactChatPanel
        selectedContactUserId={selectedContactUserId}
        selectedChatEmail={selectedChatEmail}
        selectedChatLogo={selectedChatLogo}
        selectedChatRoomId={selectedChatRoomId}
        reloadChatPanel={reloadChatPanel}
        setreloadChatPanel={setreloadChatPanel}
        chatcomponentName={chatcomponentName}
        setchatcomponentName={setchatcomponentName}
        chatComponentTitle={chatComponentTitle}
        setchatComponentTitle={setchatComponentTitle}
      />

      {/* <ConversationRightContactMailPanel /> */}

      <ConversationProfileDetailsPopup
        conversationName={conversationName}
        selectedChatLogo={selectedChatLogo}
        selectedChatEmail={selectedChatEmail}
        selectedChatPhone={selectedChatPhone}
        selectedChatAddress={selectedChatAddress}
        selectedChatCompany={selectedChatCompany}
      />
    </>
  );
};

export default ConversationRightContactPanel;
