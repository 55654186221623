/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { assetImages } from "constants";

const TeamsTab = ({ memberList }) => {
  return (
    <div className="post_item_outer">
      <div className="post_item">
        {memberList.map((member, index) => {
          return (
            <div className="white_shadow_bx people_bx" key={index}>
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img
                      src={
                        member.image == ""
                          ? assetImages.defaultUser
                          : url.SERVER_URL + member.image
                      }
                      alt=""
                    />
                  </div>
                  <div className="people_profile_info">
                    <h3>{member.name}</h3>
                    <h5>{member.email}</h5>
                    <divtotalratings className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            {member.totalratings}{" "}
                            <em>({member.totalreviews})</em>
                          </span>
                        </li>
                      </ul>
                    </divtotalratings>
                  </div>
                </div>
                <div className="right_people_btn">
                  <Link
                    to={`/peoples/details/${member.userid}`}
                    className="btn outline"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamsTab;
