/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import /images
import { assetImages } from "constants";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import languages ------------------------------------------------
import languageOptions from "../../Data/Language.json";

const AuthHeader = ({ moduleName }) => {
  const token = localStorage.getItem("token");

  const cartItemCount = localStorage.getItem("cartitemcount");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t, i18n } = useTranslation(); // translation marker

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="header_inner">
          <div className="left_serch_logo">
            <div className="hdr_hamberger">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.add("sidebar_open");
                }}
              >
                <img src="/images/menu.svg" alt="" />
              </Link>
            </div>
            <Link to="/feed'" className="small_logo">
              <img src="/images/small-logo.svg" alt="" />
            </Link>
            <div className="header_serach">
              <input
                type="text"
                // value=""
                className="form-control"
                placeholder="Search "
              />
              <button type="button" className="srch_btn">
                <span className="material-icons-outlined">search</span>
              </button>
            </div>
          </div>
          <div className="right_user_icon">
            <ul className="list_stye_none">
              <li className="search_btn">
                <Link to="#">
                  <i className="material-icons-outlined">search</i>
                </Link>
              </li>
              <li>
                <Link
                  to="/conversation"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Chat"
                >
                  <i className="material-icons-outlined">forum</i>
                </Link>
              </li>
              {/* <li>
                <Link to="/notifications">
                  <i
                    className="material-icons-outlined"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    data-bs-title="Notifications"
                  >
                    notifications
                  </i>
                  <span className="notify_nmbr">8</span>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/invitelist"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Group"
                >
                  <i className="material-icons-outlined">group</i>
                  {/* <span className="notify_nmbr">2</span> */}
                </Link>
              </li>
              <li>
                <Link
                  to="/cart"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Cart"
                >
                  <i className="material-icons-outlined">shopping_cart</i>
                  {parseInt(cartItemCount) > 0 ? (
                    <span className="notify_nmbr">{cartItemCount}</span>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
              <li>
                <Link
                  to="/pricing"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Plans"
                >
                  <i className="material-icons-outlined">euro</i>
                </Link>
              </li>

              <li className="dropdown">
                <Link
                  to="#"
                  className="user_profile_img"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src={
                      userInfo.photoimage
                        ? url.SERVER_URL + userInfo.photoimage?.path
                        : assetImages.defaultUser
                    }
                    alt=""
                  />
                </Link>
                <div className="dropdown-menu">
                  <div className="user_profile_outer d-flex align-items-center justify-content-between">
                    <div className="left_usr_info">
                      <Link
                        to="/profile"
                        className="d-flex align-items-center justify-content-center gap-2"
                      >
                        <span className="menu_usr_profile">
                          <img
                            src={
                              userInfo.photoimage
                                ? url.SERVER_URL + userInfo.photoimage?.path
                                : assetImages.defaultUser
                            }
                            alt=""
                          />
                        </span>

                        {userInfo.name ? (
                          <span>
                            {userInfo.name} {userInfo.surname}
                          </span>
                        ) : (
                          <span>{userInfo.email}</span>
                        )}
                      </Link>
                    </div>
                    <div className="right_usr_logout">
                      <Link to="/logout">
                        <i className="material-icons-outlined">logout</i>
                      </Link>
                    </div>
                  </div>
                  <ul className="list_stye_none">
                    <li>
                      <a
                        href={
                          url.BACKEND_BASE_URL +
                          `/auth/validate?email=${userInfo.email}&token=${token}`
                        }
                      >
                        <i className="material-icons-outlined">
                          space_dashboard
                        </i>
                        <span className="">{t("Dashboard")}</span>
                      </a>
                    </li>
                    <li>
                      <Link
                        to={
                          url.BACKEND_BASE_URL +
                          `/auth/validate?email=${userInfo.email}&token=${token}&redirectto=MY_PROFILE`
                        }
                      >
                        <i className="material-icons-outlined">
                          account_circle
                        </i>
                        <span className="">{t("Profile")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/mycvs">
                        <i className="material-icons-outlined">contact_page</i>
                        <span className="">{t("My CVs")}</span>
                      </Link>
                    </li>
                  </ul>

                  {/* ------- language section start ------ */}
                  <div className="language_row mt-3 d-flex align-items-center">
                    <Link
                      to="#"
                      onClick={() => {
                        i18n.changeLanguage("en");
                      }}
                      className={i18n.language === "en" ? "active" : ""}
                    >
                      <i>
                        <img src="/images/flag1.svg" alt="" />
                      </i>
                      <span>En</span>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        i18n.changeLanguage("es");
                      }}
                      className={i18n.language === "es" ? "active" : ""}
                    >
                      <i>
                        <img src="/images/flag2.svg" alt="" />
                      </i>
                      <span>Es</span>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        i18n.changeLanguage("ca");
                      }}
                      className={i18n.language === "ca" ? "active" : ""}
                    >
                      <i>
                        <img src="/images/flag3.svg" alt="" />
                      </i>
                      <span>CAT</span>
                    </Link>
                  </div>
                  {/* ------- language section end ------ */}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="hdr_lftmenu">
        <span
          className="cross_btn material-icons-outlined"
          onClick={() => document.body.classList.remove("sidebar_open")}
        >
          close
        </span>
        <div className="left_menulist">
          <ul className="list_stye_none">
            <li className={moduleName == "activity" ? "active" : ""}>
              <Link
                to="/activities"
                onClick={() => document.body.classList.remove("sidebar_open")}
              >
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">rss_feed</i>
                  <span>{t("Activities")} </span>
                </span>
              </Link>
            </li>
            <li className={moduleName == "appuser" ? "active" : ""}>
              <Link
                to="/peoples"
                onClick={() => document.body.classList.remove("sidebar_open")}
              >
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">group</i>
                  <span>{t("People")}</span>
                </span>
              </Link>
            </li>
            <li className={moduleName == "company" ? "active" : ""}>
              <Link
                to="/company"
                onClick={() => document.body.classList.remove("sidebar_open")}
              >
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">meeting_room</i>
                  <span>{t("Company")}</span>
                </span>
              </Link>
            </li>
            <li className={moduleName == "event" ? "active" : ""}>
              <Link
                to="/events"
                onClick={() => document.body.classList.remove("sidebar_open")}
              >
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">calendar_month</i>
                  <span>{t("Events")}</span>
                </span>
                {/* <span className="feed_count">9+</span> */}
              </Link>
            </li>
            <li className={moduleName == "group" ? "active" : ""}>
              <Link
                to="/groups"
                onClick={() => document.body.classList.remove("sidebar_open")}
              >
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">diversity_2</i>
                  <span>{t("Groups")}</span>
                </span>
              </Link>
            </li>

            {/* <li>
              <Link to="course.html">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">
                    collections_bookmark
                  </i>
                  <span>{t("Courses")}</span>
                </span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="job.html">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">work_outline</i>
                  <span>{t("Jobs")}</span>
                </span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AuthHeader;
