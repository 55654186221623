/* eslint-disable */

/* --------------- importing Images here --------------- */
import bannerBg from "../assets/images/banner-bg.jpg";
import bannerLogo1 from "../assets/images/banner-logo1.png";
import bannerLogo2 from "../assets/images/banner-logo2.png";
import bannerLogo3 from "../assets/images/banner-logo3.png";
import bannerLogo4 from "../assets/images/banner-logo4.png";
import bannerPic from "../assets/images/baner-pic.png";
import roadmap from "../assets/images/roadmap.jpg";
import logoKit from "../assets/images/logo-kit-digital.png";
import defaultUser from "assets/images/default-user.png";
import contactDots from "assets/images/contact-dots.png";
import dotBlueBg from "assets/images/dotblue-bg.jpg";
import congratsImage from "assets/images/congrats.png";
import modalBg from "assets/images/modal-bg.png";
import activityOne from "assets/images/activity1.jpg";
import aiLogo from "assets/images/ai-logo.png";
import emptyVector from "assets/images/empty-vector.png";
import leftBlank from "assets/images/left-blank.png";

/* --------------- importing SVGs here --------------- */
import logo from "../assets/svg/logo.svg";
import userIcon1 from "../assets/icons/userIcon1.svg";
import userIcon2 from "../assets/icons/userIcon2.svg";
import userIcon3 from "../assets/icons/userIcon3.svg";
import kitIcon1 from "../assets/icons/kitIcon1.svg";
import kitIcon2 from "../assets/icons/kitIcon2.svg";
import kitIcon3 from "../assets/icons/kitIcon3.svg";
import kitIcon4 from "../assets/icons/kitIcon4.svg";
import kitIcon5 from "../assets/icons/kitIcon5.svg";
import kitIcon6 from "../assets/icons/kitIcon6.svg";
import kitIcon7 from "../assets/icons/kitIcon7.svg";
import kitIcon8 from "../assets/icons/kitIcon8.svg";
import kitIcon9 from "../assets/icons/kitIcon9.svg";
import fv from "../assets/icons/fb.svg";
import twitter from "../assets/icons/twitter.svg";
import linkedin from "../assets/icons/linkedin.svg";
import globalLogo from "../assets/icons/global-logo.svg";
import rewardLogo from "assets/svg/rewads.svg";
import menuIcon from "assets/svg/menu.svg";
import brandLogo1 from "assets/svg/brand-logo1.svg";
import brandLogo2 from "assets/svg/brand-logo2.svg";
import brandLogo3 from "assets/svg/brand-logo3.svg";
import brandLogo4 from "assets/svg/brand-logo4.svg";
import brandLogo5 from "assets/svg/brand-logo5.svg";
import digitalKitLogo from "assets/svg/digital-kit-logo.svg";
import PdfImg from "assets/svg/pdf.svg";
import DocImg from "assets/svg/docs.svg";
import emptyDataPic from "assets/svg/lesson-empty.svg";
import embedIcon from "assets/svg/code.svg";
import whatsAppIcon from "assets/svg/whatsapp.svg";
import skypIcon from "assets/svg/skype.svg";
import facebookIcon from "assets/svg/share-facebook.svg";
import twitterIcon from "assets/svg/share-twitter.svg";
import instagramIcon from "assets/svg/share-instagram.svg";
import helloWaveSvg from "assets/svg/hlw-wave.svg";
import paymentSuccessLogo from "assets/svg/success-icon.svg";
import oopsIcon from "assets/svg/opps-icon.svg";

export default {
  // ------------------ Exporting Images here ------------------
  bannerBg,
  bannerLogo1,
  bannerLogo2,
  bannerLogo3,
  bannerLogo4,
  bannerPic,
  roadmap,
  logoKit,
  defaultUser,
  contactDots,
  dotBlueBg,
  congratsImage,
  modalBg,
  activityOne,
  aiLogo,
  emptyVector,
  leftBlank,

  // ------------------ Exporting SVGs here ------------------
  logo,
  userIcon1,
  userIcon2,
  userIcon3,
  kitIcon1,
  kitIcon2,
  kitIcon3,
  kitIcon4,
  kitIcon5,
  kitIcon6,
  kitIcon7,
  kitIcon8,
  kitIcon9,
  fv,
  twitter,
  linkedin,
  globalLogo,
  rewardLogo,
  menuIcon,
  brandLogo1,
  brandLogo2,
  brandLogo3,
  brandLogo4,
  brandLogo5,
  digitalKitLogo,
  PdfImg,
  DocImg,
  emptyDataPic,
  embedIcon,
  whatsAppIcon,
  skypIcon,
  facebookIcon,
  twitterIcon,
  instagramIcon,
  paymentSuccessLogo,
  oopsIcon,
  helloWaveSvg,
};
